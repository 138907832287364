import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@achieve/sunbeam";

import { ConditionsTableHeadProps } from "./ConditionsTableHead.interface";

const ConditionsTableHead: React.FC<ConditionsTableHeadProps> = ({
  numSelected,
  rowCount,
  onSelectAllClick,
}) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          sx={{
            backgroundColor: "white !important",
            borderBottom: "1px solid rgba(224, 224, 224, 1) !important",
          }}
        >
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>

        <TableCell
          align={"left"}
          sx={{
            backgroundColor: "white !important",
            borderBottom: "1px solid rgba(224, 224, 224, 1) !important",
          }}
        >
          <Typography fontWeight="bold">Category</Typography>
        </TableCell>

        <TableCell
          align={"right"}
          sx={{
            backgroundColor: "white !important",
            borderBottom: "1px solid rgba(224, 224, 224, 1) !important",
            width: "10px !important",
          }}
        >
          <Typography fontWeight="bold">Primary?</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default ConditionsTableHead;
