import { useFieldArray, useFormContext } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ControlledTextField from "modules/Throttling/UI/ControlledTextField/ControlledTextField";
import { getWorkingHoursConfig } from "utilities/form-config/form-config";

import s from "./WorkingHours.module.scss";

const TCell: React.FC = ({ children }) => (
  <TableCell sx={{ color: "#666", fontWeight: 600 }}>{children}</TableCell>
);

const WorkingHours: React.FC = () => {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    name: "workingHoursSettings",
    control,
  });

  const addWorkingHoursHandler = () => {
    append({ weekday: "MONDAY", fromTime: "", toTime: "" });
  };

  return (
    <div className={s.workingHours}>
      <p className={s.workingHours_title}>Working hours</p>
      <TableContainer component={Paper} className={s.workingHours_table}>
        <Table sx={{ marginTop: 0 }}>
          <TableHead
            sx={{
              background: "#f6f6f6",
            }}
          >
            <TableRow>
              <TCell>Weekday</TCell>
              <TCell>From Time</TCell>
              <TCell>To Time</TCell>
              <TCell>Delete?</TCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field: Record<"id", string>, index: number) => (
              <TableRow
                key={field.id}
                role="row"
                className={s.workingHours_row}
              >
                {getWorkingHoursConfig(index).map((config) => (
                  <TableCell key={`${config.name}-${field.id}`}>
                    <div className={s.workingHours_withShortcuts}>
                      <ControlledTextField
                        isSelect={config.isSelect}
                        name={config.name}
                        label={config.label}
                        options={config.options}
                        formFieldType={config.formFieldType}
                      />
                    </div>
                  </TableCell>
                ))}
                {index !== 0 && (
                  <TableCell>
                    <IconButton
                      onClick={() => remove(index)}
                      aria-label="delete-button"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <button
        onClick={addWorkingHoursHandler}
        type="button"
        className={s.workingHours_addBtn}
        aria-label="add-button"
      >
        <AddIcon color="success" fontSize="small" />
        Add more working hours
      </button>
    </div>
  );
};

export default WorkingHours;
