import { HTMLAttributes, useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Autocomplete, MenuItem, TextField } from "@achieve/sunbeam";
import { AutocompleteRenderOptionState } from "@mui/material/Autocomplete/Autocomplete";
import { MenuItemOption } from "entities/menu-item-option.interface";
import { get } from "lodash";

import { IAutocompleteComponentProps } from "./AutocompleteComponent.interface";
import {
  getOptionLabel,
  getSelectedOption,
  isOptionEqualToValue,
} from "./AutocompleteComponent.utils";

const renderOption = (
  props: HTMLAttributes<HTMLLIElement>,
  { value, label }: MenuItemOption,
  { selected }: AutocompleteRenderOptionState
) => {
  return (
    <MenuItem
      {...props}
      selected={selected}
      value={value}
      sx={{ fontSize: "16px" }}
    >
      {label}
    </MenuItem>
  );
};

const AutocompleteComponent: React.FC<IAutocompleteComponentProps> = ({
  name,
  label,
  className,
  helperText,
  field,
  errors,
  options = [],
  disabled,
  textFieldProps,
}) => {
  const { setValue } = useFormContext();
  const [inputValue, setInputValue] = useState(field.value ?? "");
  const [selectedOption, setSelectedOption] = useState<MenuItemOption | null>(
    () => getSelectedOption(options, field.value)
  );

  useEffect(() => {
    setSelectedOption(getSelectedOption(options, field.value));
  }, [options, field.value]);

  const handleChange = useCallback(
    (_: React.SyntheticEvent, newSelectedOption: MenuItemOption | null) => {
      setValue(name, newSelectedOption?.value ?? "");
    },
    [name, setValue]
  );

  const handleRenderInput = useCallback(
    (params) => (
      <TextField
        {...params}
        aria-label="autocomplete"
        label={label}
        name={name}
        id={`${name}-component`}
        helperText={get(errors[name], "message", helperText)}
        error={errors[name]}
        {...textFieldProps}
      />
    ),
    [errors, helperText, label, name, textFieldProps]
  );

  const handleInputChange = useCallback((_, newInputValue: string) => {
    setInputValue(newInputValue);
  }, []);

  return (
    <Autocomplete<MenuItemOption>
      disablePortal
      value={selectedOption}
      options={options}
      onChange={handleChange}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      renderInput={handleRenderInput}
      renderOption={renderOption}
      className={className}
      size={"small"}
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
};

export default AutocompleteComponent;
