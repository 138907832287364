import { IPartnerRow } from "modules/Throttling/components/ThrottlingWrapper/Throttling/Throttling.interface";

export function descendingComparator(
  a: IPartnerRow,
  b: IPartnerRow,
  property: string
) {
  const propertyA = a[property as keyof IPartnerRow] || 0;
  const propertyB = b[property as keyof IPartnerRow] || 0;

  if (propertyB < propertyA) {
    return -1;
  }
  if (propertyB > propertyA) {
    return 1;
  }
  return 0;
}
