import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { IDialogComponentProps } from "./DialogComponent.interface";

const DialogComponent: React.FC<IDialogComponentProps> = ({
  isOpen,
  title,
  content,
  submitButtonText,
  submitButtonColor,
  submitAction,
  handleClose,
  submitButtonDisabled,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {content && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={submitAction}
          autoFocus
          sx={{ color: submitButtonColor, fontWeight: 600 }}
          disabled={submitButtonDisabled}
        >
          {submitButtonText}
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogComponent;
