import { Navigate, Route, Routes } from "react-router-dom";
import { SunbeamThemeProvider } from "@achieve/sunbeam";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import {
  ErrorAlert,
  Loader,
  SnackbarContextProvider,
  SnackbarV2 as Snackbar,
} from "@sindeo/react-components";
import {
  QueryClient,
  QueryClientProvider,
  ReactQueryDevtools,
} from "@sindeo/react-data-query";
import { Settings } from "@sindeo/rest-services";
import { QUERY_CLIENT_CONFIG } from "config/queryClient.config";
import routes from "constants/routes";
import ReevaluateLoansPage from "modules/Reevaluations/components/ReevaluateLoansPage/ReevaluateLoansPage";
import ReevaluationSessionsPage from "modules/Reevaluations/components/ReevaluationSessionsPage/ReevaluationSessionsPage";
import AddRule from "modules/ReleaseToSales/components/AddRule/AddRule";
import EditRule from "modules/ReleaseToSales/components/EditRule/EditRule";
import ReleaseToSalesTable from "modules/ReleaseToSales/components/Table/Table";
import AddThrottling from "modules/Throttling/components/AddThrottling/AddThrottling";
import EditThrottling from "modules/Throttling/components/EditThrottling/EditThrottling";
import ThrottlingWrapper from "modules/Throttling/components/ThrottlingWrapper/ThrottlingWrapper";
import { ErrorNotificationContextProvider } from "modules/Throttling/context/ErrorNotification/ErrorNotification";
import ThrotllingSettingsListContextProvider from "modules/Throttling/context/ThrottlingSettingsList.context";
import { oktaAuth } from "services/okta/okta";
import AppLayout from "UI/AppLayout/AppLayout";
import Header from "UI/Header/Header";
import appConfig from "utilities/app-config/app-config";

import RequiredAuth from "components/SecureRoute/SecureRoute";

import s from "./App.module.scss";

const queryClient = new QueryClient(QUERY_CLIENT_CONFIG);

function App() {
  Settings.initConfig({
    PLATFORM: appConfig.PLATFORM,
  });

  const restoreOriginalUri = async (
    _oktaAuth: unknown,
    originalUri: string | undefined
  ) => {
    window.location.replace(
      toRelativeUrl(originalUri ?? "/", window.location.origin)
    );
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <SnackbarContextProvider>
        <SunbeamThemeProvider>
          <QueryClientProvider client={queryClient}>
            <ErrorNotificationContextProvider>
              <ThrotllingSettingsListContextProvider>
                <Header />
                <AppLayout>
                  <div className={s.page}>
                    <Routes>
                      <Route
                        path={routes.loginRedirect}
                        element={
                          <LoginCallback
                            errorComponent={ErrorAlert}
                            loadingElement={<Loader />}
                          />
                        }
                      />
                      <Route path="*" element={<RequiredAuth />}>
                        <Route
                          path="*"
                          element={<Navigate to={routes.throttlingDashboard} />}
                        />
                      </Route>
                      <Route
                        path={routes.throttlingDashboard}
                        element={<RequiredAuth />}
                      >
                        <Route path="" element={<ThrottlingWrapper />} />
                      </Route>
                      <Route
                        path={routes.addThrottling}
                        element={<RequiredAuth />}
                      >
                        <Route path="" element={<AddThrottling />} />
                      </Route>
                      <Route
                        path={routes.throttling}
                        element={<RequiredAuth />}
                      >
                        <Route path="" element={<EditThrottling />} />
                      </Route>
                      <Route
                        path={routes.rtsDashboard}
                        element={<RequiredAuth />}
                      >
                        <Route path="" element={<ReleaseToSalesTable />} />
                      </Route>
                      <Route path={routes.addRts} element={<RequiredAuth />}>
                        <Route path={""} element={<AddRule />} />
                      </Route>
                      <Route path={routes.rts} element={<RequiredAuth />}>
                        <Route path="" element={<EditRule />} />
                      </Route>
                      <Route
                        path={routes.reevaluations}
                        element={<RequiredAuth />}
                      >
                        <Route path="" element={<ReevaluationSessionsPage />} />
                      </Route>
                      <Route
                        path={routes.addSession}
                        element={<RequiredAuth />}
                      >
                        <Route path="" element={<ReevaluateLoansPage />} />
                      </Route>
                    </Routes>
                  </div>
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  />
                </AppLayout>
                {process.env.NODE_ENV === "development" && (
                  <ReactQueryDevtools position="bottom-right" />
                )}
              </ThrotllingSettingsListContextProvider>
            </ErrorNotificationContextProvider>
          </QueryClientProvider>
        </SunbeamThemeProvider>
      </SnackbarContextProvider>
    </Security>
  );
}

export default App;
