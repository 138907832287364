import { ReactElement } from "react";
import {
  TableCell,
  TableHead as BaseTableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import { TableHeadProps } from "./TableHead.interface";

import s from "./TableHead.module.scss";

const TableHead = <T extends PropertyKey>({
  columns,
  onOrderByChange,
  sort,
}: TableHeadProps<T>): ReactElement<any, any> | null => {
  const { order, orderBy } = sort;

  return (
    <BaseTableHead>
      <TableRow>
        {columns.map(({ value, align, title, sortable }) => (
          <TableCell
            key={value.toString()}
            align={align}
            className={s.tableHead}
          >
            {!sortable ? (
              title
            ) : (
              <TableSortLabel
                onClick={() => onOrderByChange(value)}
                active={orderBy === value}
                direction={orderBy === value ? order : undefined}
              >
                {title}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </BaseTableHead>
  );
};

export default TableHead;
