import { Toolbar, Typography } from "@achieve/sunbeam";

import { ConditionsTableToolbarProps } from "./ConditionsTableToolbar.interface";

const ConditionsTableToolbar: React.FC<ConditionsTableToolbarProps> = ({
  numSelected,
}) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          component="div"
          variant="displayS10"
          fontWeight="medium"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          id="tableTitle"
          component="div"
          variant="displayS10"
          fontWeight="medium"
        >
          Conditions
        </Typography>
      )}
    </Toolbar>
  );
};

export default ConditionsTableToolbar;
