import { IThrottlingData } from "@sindeo/rest-services";

export const DEFAULT_VALUES: IThrottlingData = {
  source: "",
  timezone: "America/Los_Angeles",
  leadsPerHour: "",
  leadsPerDay: "",
  throttlingType: "QUALIFIED_LEADS",
  workingHoursSettings: [
    {
      weekday: "MONDAY",
      fromTime: "",
      toTime: "",
    },
  ],
};
