import { useCallback, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@sindeo/react-components";
import { IThrottlingData } from "@sindeo/rest-services";
import { toNumber } from "lodash";
import ThrottlingForm from "modules/Throttling/components/ThrottlingForm/ThrottlingForm";
import { ThrotllingSettingsListContext } from "modules/Throttling/context/ThrottlingSettingsList.context";
import useProcessError from "modules/Throttling/hooks/useProcessError/useProcessError";
import useThrottlingSettingsById from "modules/Throttling/hooks/useThrottlingSettingsById/useThrottlingSettingsById";

const EditThrottling = () => {
  const { id } = useParams();
  const throttlingSettings = useThrottlingSettingsById(toNumber(id));
  const navigate = useNavigate();
  const { processError } = useProcessError();

  const {
    get: { isFetching },
    update: { updateThrottlingSettings, isUpdating },
  } = useContext(ThrotllingSettingsListContext);

  const handleSubmit = useCallback(
    (values: IThrottlingData) => {
      const updateData = {
        id: toNumber(id),
        values,
      };
      updateThrottlingSettings(updateData);
    },
    [id, updateThrottlingSettings]
  );

  if (isFetching) {
    return <Loader />;
  } else if (!throttlingSettings) {
    navigate("/");
    processError(
      null,
      `Partner with ID ${id} doesn't exist. Perhaps it was deleted?`
    );
    return null;
  }

  const {
    source,
    timezone,
    leadsPerHour,
    leadsPerDay,
    throttlingType,
    workingHoursSettings,
  } = throttlingSettings;

  const defaultValues: IThrottlingData = {
    source,
    timezone,
    leadsPerHour,
    leadsPerDay,
    throttlingType,
    workingHoursSettings,
  };

  return (
    <ThrottlingForm
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      isLoading={isUpdating}
    />
  );
};

export default EditThrottling;
