import { useCallback, useContext, useState } from "react";
import { Link } from "@achieve/sunbeam";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { Loader } from "@sindeo/react-components";
import { ThrotllingSettingsListContext } from "modules/Throttling/context/ThrottlingSettingsList.context";
import { findDeletingById } from "utilities/common.utils";

import DialogComponent from "components/DialogComponent/DialogComponent";

import { IThrottlingTableRowProps } from "./ThrottlingTableRow.interface";

const ThrottlingTableRow: React.FC<IThrottlingTableRowProps> = ({ row }) => {
  const { id, leadsPerDay, leadsPerHour, source } = row;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const {
    delete: { deletingIds, deleteThrottlingSettings },
  } = useContext(ThrotllingSettingsListContext);

  const handleOpenDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const handleSubmit = useCallback(() => {
    setIsDialogOpen(false);
    deleteThrottlingSettings(id);
  }, [deleteThrottlingSettings, id]);

  return (
    <>
      <TableRow key={id}>
        <TableCell sx={{ fontWeight: 600 }}>
          <Link href={`/throttling/${id}`}>{source}</Link>
        </TableCell>
        <TableCell align="center">{leadsPerHour}</TableCell>
        <TableCell align="center">{leadsPerDay}</TableCell>
        <TableCell>
          <Link href={`/throttling/${id}`}>
            <IconButton>
              <EditIcon sx={{ color: "orange" }} />
            </IconButton>
          </Link>
          <IconButton onClick={handleOpenDialog} aria-label="delete-button">
            {findDeletingById(deletingIds, id) ? (
              <Loader />
            ) : (
              <DeleteIcon sx={{ color: "red" }} />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <DialogComponent
        isOpen={isDialogOpen}
        title={`Would you like to delete ${source}?`}
        submitButtonText="Delete"
        submitButtonColor="red"
        handleClose={handleCloseDialog}
        submitAction={handleSubmit}
      />
    </>
  );
};

export default ThrottlingTableRow;
