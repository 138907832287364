import { NavLink } from "react-router-dom";
import { Typography } from "@achieve/sunbeam";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import DescriptionIcon from "@mui/icons-material/Description";
import RepeatIcon from "@mui/icons-material/Repeat";
import routes from "constants/routes";
import { useLDFlags } from "hooks/useLDFlags/useLDFlags";

import s from "./NavigationBar.module.scss";

const NAV_ITEMS = [
  {
    title: "Throttling Dashboard",
    link: routes.throttlingDashboard,
    icon: <DashboardCustomizeIcon fontSize="inherit" />,
  },
  {
    title: "Release Leads to Sales",
    link: routes.rtsDashboard,
    icon: <DescriptionIcon fontSize="inherit" />,
  },
];
const REEVALUATIONS_NAV_ITEM = {
  title: "Re-evaluations",
  link: routes.reevaluations,
  icon: <RepeatIcon fontSize="inherit" />,
};

const NavigationBar = () => {
  const { reevaluationsPageEnabled } = useLDFlags();
  const navItems = reevaluationsPageEnabled
    ? [...NAV_ITEMS, REEVALUATIONS_NAV_ITEM]
    : NAV_ITEMS;

  return (
    <aside className={s.container}>
      <ul className={s.list}>
        {navItems.map((item) => (
          <li className={s.listItem} key={item.title}>
            <NavLink
              to={item.link}
              className={({ isActive }) => (isActive ? s.linkActive : s.link)}
            >
              {item.icon}
              <Typography variant="displayXS30">{item.title}</Typography>
            </NavLink>
          </li>
        ))}
      </ul>
    </aside>
  );
};

export default NavigationBar;
