import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { Loader } from "@sindeo/react-components";
import { useLDFlags } from "hooks/useLDFlags/useLDFlags";

const RequiredAuth: React.FC = () => {
  const { oktaAuthEnabled } = useLDFlags();
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (oktaAuthEnabled) {
      if (!authState) {
        return;
      }

      if (!authState?.isAuthenticated) {
        const originalUri = toRelativeUrl(
          window.location.href,
          window.location.origin
        );

        oktaAuth.setOriginalUri(originalUri);
        oktaAuth.signInWithRedirect();
      }
    }
  }, [oktaAuth, authState, oktaAuthEnabled]);

  if (oktaAuthEnabled && (!authState || !authState?.isAuthenticated)) {
    return <Loader />;
  }

  return <Outlet />;
};

export default RequiredAuth;
