import type { DeclineReason, ReevaluationData } from "@sindeo/rest-services";
import moment from "moment";
import * as yup from "yup";

import type { ReevaluateLoansFormValues } from "./ReevaluateLoansPage.interface";

const isDateValid = (date: any): boolean =>
  date instanceof Date && !isNaN(date.valueOf());

export const defaultFormValues: ReevaluateLoansFormValues = {
  title: "",
  source: "",
  declineReason: "",
  startDate: null,
  endDate: null,
};

export const validationSchema = yup.object<ReevaluateLoansFormValues>({
  title: yup.string(),
  source: yup.string(),
  declineReason: yup.string().required("Decline reason is required"),
  startDate: yup
    .date()
    .typeError("Must be a date")
    .max(new Date(), "Date should be before current time")
    .required("Start date is required"),
  endDate: yup
    .date()
    .typeError("Must be a date")
    .max(new Date(), "Date should be before current time")
    .when("startDate", ([startDate]: any[], schema: any) => {
      return isDateValid(startDate)
        ? schema.min(startDate, "End time cannot be before start time")
        : schema;
    })
    .nullable(),
});

export const getPayload = (
  formValues: ReevaluateLoansFormValues
): ReevaluationData => {
  const { title, source, startDate, endDate, declineReason } = formValues;

  return {
    title,
    source,
    declineReason: declineReason as DeclineReason,
    startDate: moment(startDate).toISOString(),
    endDate: endDate ? moment(endDate).toISOString() : undefined,
  };
};
