import InputMask from "react-input-mask";
import { TextField, TextFieldProps } from "@material-ui/core";
import { get } from "lodash";
import Timeshortcuts from "modules/Throttling/UI/Timeshortcuts/Timeshortcuts";

import { ITimepickerComponentProps } from "./TimepickerComponent.interface";

const TimepickerComponent: React.FC<ITimepickerComponentProps> = ({
  field,
  label,
  name,
  className,
  errors,
}) => {
  const mask = "12:34:56";
  const formatChars = {
    "1": "[0-2]",
    "2": "[0-9]",
    "3": "[0-5]",
    "4": "[0-9]",
    "5": "[0-5]",
    "6": "[0-9]",
  };

  const timepickerErrors = get(errors, name, {});
  const errorMessage = timepickerErrors.message;

  return (
    <>
      <InputMask formatChars={formatChars} mask={mask} {...field}>
        {(inputProps: TextFieldProps) => (
          <TextField
            {...inputProps}
            label={label}
            id={`${name}-component`}
            variant="outlined"
            size="small"
            className={className}
            error={Boolean(errorMessage)}
            helperText={errorMessage}
          />
        )}
      </InputMask>
      <Timeshortcuts name={name} />
    </>
  );
};

export default TimepickerComponent;
