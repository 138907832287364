import { matchPath } from "react-router";
import { Link as RouterLink, useLocation } from "react-router-dom";
import BreadcrumbsElement from "@mui/material/Breadcrumbs";
import Link, { LinkProps } from "@mui/material/Link";
import cn from "classnames";
import { toNumber } from "lodash";
import useThrottlingSettingsById from "modules/Throttling/hooks/useThrottlingSettingsById/useThrottlingSettingsById";

import { getBreadcrumbsMap } from "./Breadcrumbs.config";

import s from "./Breadcrumbs.module.scss";

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink} />
);

const Breadcrumbs: React.FC = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const pathnames = location.pathname.split("/").filter((x) => x);

  const id = toNumber(
    matchPath({ path: "/throttling/:id" }, pathname)?.params.id
  );

  const throttlingSettings = useThrottlingSettingsById(id);

  const breadcrumbsMap = getBreadcrumbsMap(id, throttlingSettings?.source);

  return (
    <BreadcrumbsElement className={s.breadcrumbs}>
      <LinkRouter underline="hover" to="/" className={s.link}>
        Home
      </LinkRouter>
      {pathnames.map((_pathname, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;

        const linkClasses = cn(s.link, {
          [s.last]: last,
        });
        return (
          <LinkRouter
            underline="hover"
            to={to}
            key={_pathname}
            className={linkClasses}
          >
            {breadcrumbsMap[to]}
          </LinkRouter>
        );
      })}
    </BreadcrumbsElement>
  );
};

export default Breadcrumbs;
