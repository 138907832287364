import { SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Typography } from "@achieve/sunbeam";
import { Loader } from "@sindeo/react-components";
import { toNumber } from "lodash";
import useUpdateRulesEndpoints from "modules/ReleaseToSales/hooks/useUpdateRulesEndpoints/useUpdateRulesEndpoints";

import RulesForm from "../RulesForm/RulesForm";
import { RulesFormValues } from "../RulesForm/RulesForm.interface";

const EditRule = () => {
  const { id } = useParams();
  const {
    ruleById,
    isRuleFetching,
    isRuleLoading,
    isRuleError,
    editRule,
    isRuleEditing,
  } = useUpdateRulesEndpoints(toNumber(id));
  const navigate = useNavigate();

  if (isRuleFetching || isRuleLoading) {
    return <Loader />;
  }

  if (isRuleError) {
    return (
      <Typography data-testId="error-message">
        There is an error while fetching rule
      </Typography>
    );
  }

  if (!ruleById) {
    navigate("/release-to-sales");
    return null;
  }

  const defaultValues: RulesFormValues = {
    partner: ruleById.partner,
    active: ruleById.active,
    conditionIds: ruleById.conditions?.map((condition) => condition.id),
    primaryId:
      ruleById.conditions.find((condition) => condition.isPrimary)?.id ??
      undefined,
  };

  const onSubmit: SubmitHandler<any> = (values: any) => {
    editRule(values);
  };

  return (
    <RulesForm
      isLoading={isRuleEditing}
      onSubmit={onSubmit}
      pageText={"Edit rule"}
      defaultValues={defaultValues}
      isReadonly
    />
  );
};

export default EditRule;
