import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Checkbox, Radio, TableCell, TableRow } from "@achieve/sunbeam";

import { ConditionsTableRowProps } from "./ConditionsTableRow.interface";
import { getHandleConditionChange } from "./ConditionsTableRow.utils";

const ConditionsTableRow: React.FC<ConditionsTableRowProps> = ({
  condition,
  isItemSelected,
}) => {
  const { id, description } = condition;

  const { control, watch, setValue } = useFormContext();

  const primaryId = watch("primaryId");
  const conditionIds = watch("conditionIds");

  useEffect(() => {
    if (!conditionIds?.includes(primaryId)) {
      setValue("primaryId", undefined);
    }
  }, [conditionIds, primaryId, setValue]);

  const labelId = `table-checkbox-${id}`;

  return (
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
      sx={{ cursor: "pointer" }}
    >
      <Controller
        control={control}
        name="conditionIds"
        render={({ field }) => {
          const safeConditionIds = field.value || [];

          const onChange = getHandleConditionChange(
            safeConditionIds,
            condition.id,
            field
          );

          return (
            <>
              <TableCell padding="checkbox" onClick={onChange}>
                <Checkbox
                  {...field}
                  onChange={onChange}
                  color="primary"
                  checked={safeConditionIds.indexOf(condition.id) > -1}
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </TableCell>

              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="none"
                onClick={onChange}
              >
                {description}
              </TableCell>
            </>
          );
        }}
      />

      <Controller
        control={control}
        name="primaryId"
        render={({ field }) => {
          return (
            <TableCell align="right" sx={{ cursor: "initial" }}>
              <Radio
                {...field}
                onChange={() => field.onChange(condition.id)}
                checked={field.value === condition.id}
                disabled={!isItemSelected}
              />
            </TableCell>
          );
        }}
      />
    </TableRow>
  );
};

export default ConditionsTableRow;
