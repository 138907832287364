import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";
import useTableHandlers from "modules/Throttling/hooks/useTableHandlers/useTableHandlers";
import AddThrottlingButton from "modules/Throttling/UI/AddThrottlingButton/AddThrottlingButton";
import ErrorNotification from "modules/Throttling/UI/ErrorNotification/ErrorNotification";

import ThrottlingTableHeader from "../ThrottlingTableHeader/ThrottlingTableHeader";
import ThrottlingTableRow from "../ThrottlingTableRow/ThrottlingTableRow";

import { getDataSource } from "./Throttling.config";
import { IThrottlingProps } from "./Throttling.interface";

import s from "./Throttling.module.scss";

const Throttling = ({ throtllingSettingsList }: IThrottlingProps) => {
  const rows = getDataSource(throtllingSettingsList);

  const {
    getComparator,
    order,
    orderBy,
    handleRequestSort,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTableHandlers();

  return (
    <>
      <AddThrottlingButton />
      <Paper sx={{ maxWidth: 600 }}>
        <TableContainer className={s.tableContainer}>
          <Table sx={{ marginTop: 0 }}>
            <ThrottlingTableHeader
              order={order}
              orderBy={orderBy}
              handleRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows
                .slice()
                .sort(getComparator())
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <ThrottlingTableRow row={row} key={row.id} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={s.pagination}
        />
      </Paper>

      <ErrorNotification />
    </>
  );
};

export default Throttling;
