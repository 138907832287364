import { FunctionComponent, useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "@achieve/sunbeam";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Loader } from "@sindeo/react-components";
import type { ReevaluationSession } from "@sindeo/rest-services";
import routes from "constants/routes";
import type { SortConfig } from "entities/types/order.type";
import useTableHandlers from "hooks/useTableHandlers/useTableHandlers";
import { toNumber } from "lodash";
import ReevaluationSessionRow from "modules/Reevaluations/components/ReevaluationSessionRow/ReevaluationSessionRow";
import { SessionsTableFilterBar } from "modules/Reevaluations/components/SessionsTableFilterBar/SessionsTableFilterBar";
import { ReevaluationsTableCols } from "modules/Reevaluations/entities/sortableFields.type";
import useReevaluationSessions from "modules/Reevaluations/hooks/useReevaluationSessions/useReevaluationSessions";

import TableHead from "components/TableHead/TableHead";

import { COLUMNS } from "./ReevaluationSessionsTable.utils";

import styles from "./ReevaluationSessionsTable.module.scss";

const defaultSort: SortConfig<ReevaluationsTableCols> = {
  order: "desc",
  orderBy: "createdAt",
};

const ReevaluationSessionsTable: FunctionComponent = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentParams = Object.fromEntries(Array.from(searchParams));
  const {
    page,
    size,
    sort,
    handleChangePage,
    handleRowsPerPageChange,
    handleChangeOrder,
  } = useTableHandlers<ReevaluationsTableCols>(defaultSort);
  const { data, isLoading } = useReevaluationSessions({
    size,
    page,
    ...sort,
    ...currentParams,
  });
  const { content, totalElements } = data ?? {};

  useEffect(() => {
    if (data && page !== 0 && (page >= data?.totalPages || isNaN(page))) {
      handleChangePage(undefined, 0);
    }
  }, [data, handleChangePage, page]);

  const handleFilterSubmit = useCallback(
    (values: Record<string, string>) => {
      setSearchParams({ ...currentParams, ...values });
    },
    [currentParams, setSearchParams]
  );

  const handleFilterReset = useCallback(() => {
    searchParams.delete("status");
    searchParams.delete("source");
    searchParams.delete("declineReason");
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const handleAddClick = useCallback(() => {
    navigate(routes.addSession);
  }, [navigate]);

  return (
    <Grid container={true} spacing={2} direction={"column"}>
      <Grid
        item={true}
        container={true}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item={true} xs={"auto"}>
          <Button size={"small"} onClick={handleAddClick}>
            {"Re-evaluate Loans"}
          </Button>
        </Grid>
        <Grid item={true} xs={"auto"}>
          <SessionsTableFilterBar
            initialValues={currentParams}
            onSubmit={handleFilterSubmit}
            onReset={handleFilterReset}
          />
        </Grid>
      </Grid>
      <Grid item={true}>
        <TableContainer component={Paper} className={styles.tableContainer}>
          <Table stickyHeader className={styles.reevaluationsTable}>
            <TableHead<ReevaluationsTableCols>
              columns={COLUMNS}
              onOrderByChange={handleChangeOrder}
              sort={sort}
            />
            <TableBody>
              {!content?.length && (
                <TableRow>
                  <TableCell colSpan={COLUMNS.length} align={"center"}>
                    {isLoading ? <Loader /> : "No sessions found"}
                  </TableCell>
                </TableRow>
              )}
              {content?.map((session: ReevaluationSession) => (
                <ReevaluationSessionRow key={session.id} data={session} />
              ))}
            </TableBody>
          </Table>
          {Boolean(totalElements) && (
            <TablePagination
              className={styles.pagination}
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={toNumber(totalElements)}
              rowsPerPage={toNumber(size)}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          )}
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ReevaluationSessionsTable;
