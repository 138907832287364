import { OktaAuth, OktaAuthOptions } from "@okta/okta-auth-js";
import routes from "constants/routes";
import config from "utilities/app-config/app-config";

const oktaConfig: OktaAuthOptions = {
  clientId: `${config.OKTA_CLIENT_ID}`,
  issuer: `${config.OKTA_ISSUER}`,
  redirectUri: `${window.location.origin}${routes.loginRedirect}`,
  scopes: ["openid", "profile", "email"],
  pkce: true,
};

export const oktaAuth = new OktaAuth(oktaConfig);
