import { FunctionComponent } from "react";
import { DECLINE_REASONS } from "@sindeo/rest-services";
import classNames from "classnames";
import { IFormConfig } from "entities/form-config.interface";
import ControlledTextField from "modules/Throttling/UI/ControlledTextField/ControlledTextField";

import type { DeclineReasonsFormItemProps } from "./DeclineReasonsFormItem.interface";

import styles from "./DeclineReasonsFormItem.module.scss";

const DECLINE_REASONS_MENU_ITEMS: IFormConfig["options"] = DECLINE_REASONS.map(
  (item) => ({
    label: item,
    value: item,
  })
);

export const DeclineReasonsFormItem: FunctionComponent<
  DeclineReasonsFormItemProps
> = ({ className, ...restProps }) => {
  return (
    <ControlledTextField
      formFieldType={"autocomplete"}
      options={DECLINE_REASONS_MENU_ITEMS}
      name={"declineReason"}
      label={"Decline Reason"}
      className={classNames(styles.autocomplete, className)}
      textFieldProps={restProps}
    />
  );
};
