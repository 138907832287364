import { useCallback, useState } from "react";
import { Order } from "entities/types/order.type";
import { IPartnerRow } from "modules/Throttling/components/ThrottlingWrapper/Throttling/Throttling.interface";
import { descendingComparator } from "utilities/table-handlers/table-handlers";

import { IUseTableHandlers } from "./useTableHandlers.interface";

const useTableHandlers = (): IUseTableHandlers => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof IPartnerRow>("source");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const handleRequestSort = (
    _: React.MouseEvent<unknown>,
    property: keyof IPartnerRow
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getComparator = useCallback((): ((
    a: IPartnerRow,
    b: IPartnerRow
  ) => number) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }, [order, orderBy]);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return {
    order,
    orderBy,
    page,
    rowsPerPage,
    handleRequestSort,
    getComparator,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};

export default useTableHandlers;
