import { FilterBarFormValues } from "./SessionsTableFilterBar.interface";

export const filterBarDefaultFormValues: FilterBarFormValues = {
  source: "",
  status: "",
  declineReason: "",
};

export const getInitialFormValues = (
  searchParams?: Partial<FilterBarFormValues>
): FilterBarFormValues => {
  return {
    source: searchParams?.source ?? "",
    status: searchParams?.status ?? "",
    declineReason: searchParams?.declineReason ?? "",
  };
};
