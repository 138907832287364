import { useContext } from "react";
import ThrottlingForm from "modules/Throttling/components/ThrottlingForm/ThrottlingForm";
import { ThrotllingSettingsListContext } from "modules/Throttling/context/ThrottlingSettingsList.context";

import { DEFAULT_VALUES } from "./AddThrottling.constants";

const AddThrottling = () => {
  const {
    create: { createThrottlingSettings, isCreating },
  } = useContext(ThrotllingSettingsListContext);

  return (
    <ThrottlingForm
      defaultValues={DEFAULT_VALUES}
      onSubmit={createThrottlingSettings}
      isLoading={isCreating}
    />
  );
};

export default AddThrottling;
