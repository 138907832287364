import { useCallback, useState } from "react";

const useRulesTableRow = (id: number, deleteRule: (id: number) => void) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleOpenDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const handleSubmit = useCallback(() => {
    handleCloseDialog();
    deleteRule(id);
  }, [handleCloseDialog, deleteRule, id]);

  return { isDialogOpen, handleOpenDialog, handleCloseDialog, handleSubmit };
};

export default useRulesTableRow;
