import { SubmitHandler } from "react-hook-form";
import useUpdateRulesEndpoints from "modules/ReleaseToSales/hooks/useUpdateRulesEndpoints/useUpdateRulesEndpoints";

import RulesForm from "../RulesForm/RulesForm";
import { RulesFormValues } from "../RulesForm/RulesForm.interface";

const AddRule = () => {
  const { addRule, isRuleAdding } = useUpdateRulesEndpoints();

  const defaultValues: RulesFormValues = {
    partner: "",
    active: true,
    conditionIds: [],
    primaryId: undefined,
  };

  const onSubmit: SubmitHandler<any> = async (values: any) => {
    await addRule(values);
  };

  return (
    <RulesForm
      isLoading={isRuleAdding}
      onSubmit={onSubmit}
      pageText={"Add rule"}
      defaultValues={defaultValues}
    />
  );
};

export default AddRule;
