import type { TableHeadConfig } from "entities/table-head-config.interface";
import type { ReevaluationsTableCols } from "modules/Reevaluations/entities/sortableFields.type";

export const COLUMNS: TableHeadConfig<ReevaluationsTableCols>[] = [
  {
    title: "",
    value: "expand",
  }, // column for collapsible row icon
  {
    title: "Title",
    value: "title",
    sortable: true,
  },
  {
    title: "Status",
    value: "status",
    sortable: true,
  },
  {
    value: "createdAt",
    title: "Created At",
    sortable: true,
  },
  {
    title: "Start Date",
    value: "startDate",
    sortable: true,
  },
  {
    title: "End Date",
    value: "endDate",
    sortable: true,
  },
  {
    title: "Source",
    value: "source",
    sortable: true,
  },
  {
    title: "Decline Reason",
    value: "declineReason",
    sortable: true,
  },
  {
    title: "",
    value: "actions",
  }, // column for actions
];
