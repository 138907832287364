import { FunctionComponent, useCallback, useState } from "react";
import { Button } from "@achieve/sunbeam";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import type { AxiosError } from "axios";
import useSessionActions from "modules/Reevaluations/hooks/useSessionActions/useSessionActions";
import { isIncompatibleSessionStatusError } from "modules/Reevaluations/hooks/useSessionActions/useSessionActions.utils";

import DialogComponent from "components/DialogComponent/DialogComponent";

import type { AbortSessionButtonProps } from "./AbortSessionButton.interface";

import styles from "./AbortSessionButton.module.scss";

const AbortSessionButton: FunctionComponent<AbortSessionButtonProps> = ({
  id,
  title,
}) => {
  const { abortSession, isLoading } = useSessionActions();
  const [modalOpen, setModalOpen] = useState(false);
  const titleLabel = title ? ` "${title}"` : "";

  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleAbort = useCallback(async () => {
    try {
      await abortSession(id);
      handleModalClose();
    } catch (error) {
      if (isIncompatibleSessionStatusError(error as AxiosError)) {
        handleModalClose();
      }
    }
  }, [abortSession, id, handleModalClose]);

  return (
    <>
      <Button
        size={"xsmall"}
        variant={"text"}
        startIcon={
          <DangerousOutlinedIcon color={"inherit"} fontSize={"small"} />
        }
        classes={{
          root: styles.button,
          startIcon: styles.buttonIcon,
        }}
        onClick={handleModalOpen}
      >
        {"Abort Session"}
      </Button>
      <DialogComponent
        isOpen={modalOpen}
        title={`Would you like to abort session${titleLabel}?`}
        submitButtonText={"Abort"}
        submitButtonColor={"red"}
        submitButtonDisabled={isLoading}
        handleClose={handleModalClose}
        submitAction={handleAbort}
      />
    </>
  );
};

export default AbortSessionButton;
