import appConfigDev from "config/app.config.dev.json";
import appConfigPrd from "config/app.config.prd.json";
import appConfigQa from "config/app.config.qa.json";
import appConfigStg from "config/app.config.stg.json";
import { IAppConfig } from "entities/app-config.interface";
import { Platform } from "entities/types/platform.type";

const configMap: Record<Platform, IAppConfig> = {
  dev: appConfigDev as IAppConfig,
  qa: appConfigQa as IAppConfig,
  stg: appConfigStg as IAppConfig,
  prd: appConfigPrd as IAppConfig,
};

export function getCurrentPlatform(): Platform {
  const { hostname } = window.location;

  if (hostname.includes(".prd.") || hostname.includes("lendage.com")) {
    return "prd";
  }

  if (hostname.includes(".stg.")) {
    return "stg";
  }

  if (hostname.includes(".qa.") || hostname.includes("localhost")) {
    return "qa";
  }

  return "dev";
}

const platform = getCurrentPlatform();
const config: IAppConfig = configMap[platform];

export default config;
