import { useQuery } from "@sindeo/react-data-query";
import { Rest } from "services/services";

const useConditions = () => {
  const {
    data: conditions,
    isLoading: isConditionsLoading,
    isFetching: isConditionsFetching,
    isError: isConditionsError,
  } = useQuery(["conditions"], () => Rest.rls.getReleaseConditions(), {
    initialData: null,
    refetchOnWindowFocus: false,
  });

  return {
    conditions,
    isConditionsLoading,
    isConditionsFetching,
    isConditionsError,
  };
};

export default useConditions;
