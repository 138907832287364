import { IThrottlingSettings } from "@sindeo/rest-services";

import { IPartnerRow } from "./Throttling.interface";

export const HEADER_CELLS: { title: string; propName: keyof IPartnerRow }[] = [
  { title: "Title", propName: "source" },
  { title: "Leads per hour", propName: "leadsPerHour" },
  { title: "Leads per day", propName: "leadsPerDay" },
];

export const createData = (
  throttlingSettings: IThrottlingSettings
): IPartnerRow => {
  const { id, source, leadsPerHour, leadsPerDay } = throttlingSettings;

  return {
    id,
    source,
    leadsPerHour,
    leadsPerDay,
  };
};

export const getDataSource = (
  throttlingSettingsList: IThrottlingSettings[]
): IPartnerRow[] => {
  return throttlingSettingsList.map((throttlingSettings) =>
    createData(throttlingSettings)
  );
};
