import { DEX_ID } from "modules/ReleaseToSales/constants/common";
import * as yup from "yup";

import { RulesFormValues } from "./RulesForm.interface";

function hasDexCondition(this: {
  parent: { conditionIds: number[] | undefined };
}) {
  const { parent } = this;
  const dexCondition = parent.conditionIds?.includes(DEX_ID);

  return Boolean(dexCondition);
}

export const VALIDATION_SCHEMA = yup.object({
  partner: yup
    .string()
    .required("Partner is required")
    .trim("Partner field cannot include leading and trailing spaces"),
  isActive: yup.boolean(),
  primaryId: yup
    .number()
    .required("One condition from the list should be primary"),
  conditionIds: yup
    .array()
    .of(yup.number())
    .min(1, "At least one condition should be selected")
    .test(
      "has DEX test",
      "DEX status is LIMITED or HYBRID condition should be selected",
      hasDexCondition
    ),
});

export const processValues = (values: RulesFormValues) => {
  const conditions = values.conditionIds.map((id) => ({
    id,
    isPrimary: values.primaryId === id,
  }));

  const processedValues = {
    partner: values.partner,
    active: values.active,
    conditions,
  };

  return processedValues;
};
