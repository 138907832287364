import { useSnackbar } from "@sindeo/react-components";
import { useMutation, useQueryClient } from "@sindeo/react-data-query";
import type { ReevaluationData } from "@sindeo/rest-services";
import type { AxiosError } from "axios";
import { GET_SESSIONS_QUERY_KEY } from "modules/Reevaluations/hooks/useReevaluationSessions/useReevaluationSessions";
import { logger } from "services/logger";
import { Rest } from "services/services";

import type { UseSessionActionsResult } from "./useSessionActions.interface";
import { isIncompatibleSessionStatusError } from "./useSessionActions.utils";

const useSessionActions = (): UseSessionActionsResult => {
  const notification = useSnackbar();
  const queryClient = useQueryClient();

  const { mutateAsync: abortSession, isLoading: isAbortLoading } = useMutation({
    mutationFn: (id: number) => Rest.pls.abortReevaluationSession(id),
    onSuccess: () => {
      notification.success({
        message: "Session was successfully stopped",
      });
      queryClient.refetchQueries([GET_SESSIONS_QUERY_KEY]);
    },
    onError: (err: AxiosError) => {
      if (isIncompatibleSessionStatusError(err)) {
        queryClient.refetchQueries([GET_SESSIONS_QUERY_KEY]);
      }
      logger.error("Unable to stop session.", err);
      notification.error({
        message: "Unable to stop session.",
      });
    },
  });

  const { mutateAsync: createSession, isLoading: isCreateLoading } =
    useMutation({
      mutationFn: (data: ReevaluationData) => Rest.pls.reevaluateLoans(data),
      onSuccess: () => {
        notification.success({
          message: "Session was successfully created",
        });
      },
      onError: (err: Error) => {
        logger.error("Unable to create session.", err);
        notification.error({
          message: "Unable to create session.",
        });
      },
    });

  return {
    createSession,
    abortSession,
    isLoading: isAbortLoading || isCreateLoading,
  };
};

export default useSessionActions;
