import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@sindeo/react-data-query";
import { IThrottlingData, IThrottlingSettings } from "@sindeo/rest-services";
import useProcessError from "modules/Throttling/hooks/useProcessError/useProcessError";
import { Rest } from "services/services";

import { IUseThrottlingEndpoints } from "./useThrottlingEndpoints.interface";

const useThrottlingEndpoints = (): IUseThrottlingEndpoints => {
  const { processError } = useProcessError();
  const [deletingIds, setDeletingIds] = useState<number[]>([]);
  const navigate = useNavigate();

  const {
    data: throttlingSettingsList,
    isFetching,
    refetch,
  } = useQuery<IThrottlingSettings[] | null, boolean>(
    ["throttlingSettingsList"],
    () => {
      return Rest.pls.getThrottlingSettings();
    },
    {
      initialData: null,
      refetchOnWindowFocus: false,
      onError: (error) => {
        processError(error);
      },
    }
  );

  const { mutate: createThrottlingSettings, isLoading: isCreating } =
    useMutation(
      (values: IThrottlingData) => Rest.pls.createThrottlingSettings(values),
      {
        mutationKey: ["throttlingSettingsListAdd"],
        onSuccess: () => {
          refetch();
          navigate("/throttling");
        },
        onError: (error) => processError(error),
      }
    );

  const { mutate: updateThrottlingSettings, isLoading: isUpdating } =
    useMutation(
      (updateData: { id: number; values: IThrottlingData }) => {
        return Rest.pls.updateThrottlingSettings(
          updateData.id,
          updateData.values
        );
      },
      {
        mutationKey: ["throttlingSettingsListEdit"],
        onSuccess: () => {
          refetch();
          navigate("/throttling");
        },
        onError: (error) => processError(error),
      }
    );

  const { mutate: deleteThrottlingSettings } = useMutation(
    (id: number) => {
      setDeletingIds([...deletingIds, id]);
      return Rest.pls.deleteThrottlingSettings(id);
    },
    {
      mutationKey: ["throttlingSettingsListDelete"],
      onSuccess: () => {
        refetch();
      },
      onError: (error) => processError(error),
    }
  );

  return {
    get: {
      throttlingSettingsList,
      isFetching,
    },
    delete: {
      deletingIds,
      deleteThrottlingSettings,
    },
    create: {
      createThrottlingSettings,
      isCreating,
    },
    update: {
      updateThrottlingSettings,
      isUpdating,
    },
  };
};

export default useThrottlingEndpoints;
