import { useCallback, useEffect, useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Button, Grid, MenuItem, TextField } from "@achieve/sunbeam";
import { OrderBy } from "@sindeo/rest-services";
import { MenuItemOption } from "entities/menu-item-option.interface";
import ControlledTextField from "modules/Throttling/UI/ControlledTextField/ControlledTextField";

import { getFilterOptions, getSelectItems } from "./FilterPanel.config";
import { FilterPanelProps, FilterValues } from "./FilterPanel.interface";

import s from "./FilterPanel.module.scss";

const FilterPanel = ({
  partners,
  conditions,
  onSubmit,
  onReset,
  defaultSearchParams,
  isLoading,
}: FilterPanelProps) => {
  const methods = useForm<FilterValues>({
    defaultValues: defaultSearchParams,
  });
  const { control, handleSubmit, watch, reset, setValue } = methods;

  const handleReset = useCallback(() => {
    onReset();
    reset({ orderBy: "", filterBy: "" });
  }, [onReset, reset]);

  const orderByValue = watch("orderBy") as OrderBy;
  const filterByValue = watch("filterBy");

  useEffect(() => {
    if (
      filterByValue &&
      !isLoading &&
      !getFilterOptions(orderByValue, partners, conditions).find(
        (option) => option.value === filterByValue
      )
    ) {
      setValue("filterBy", "");
    }
  }, [isLoading, filterByValue, orderByValue, partners, conditions, setValue]);

  const selectItems = useMemo(
    () => getSelectItems(orderByValue, partners, conditions),
    [orderByValue, partners, conditions]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{ marginBottom: "20px" }}
        >
          <Grid item xs={4}>
            <Button
              size="small"
              href="/release-to-sales/add"
              className={s.addLink}
            >
              Add rule
            </Button>
          </Grid>
          {selectItems.map(
            ({ name, label, disabled, isAutocomplete, options }) => (
              <Grid item xs={3} key={name}>
                {isAutocomplete ? (
                  <ControlledTextField
                    formFieldType={"autocomplete"}
                    name={name}
                    options={options}
                    label={label}
                    disabled={disabled || isLoading}
                  />
                ) : (
                  <Controller
                    control={control}
                    name={name}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id={name}
                        select
                        label={label}
                        sx={{ width: "100%" }}
                        size="small"
                        disabled={disabled}
                      >
                        {options.map((option: MenuItemOption) => (
                          <MenuItem
                            value={option.value}
                            sx={{ fontSize: "16px" }}
                            key={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                )}
              </Grid>
            )
          )}
          <Grid item xs={2}>
            <Grid container alignContent={"center"}>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  size="small"
                  disabled={!orderByValue || !filterByValue}
                >
                  Apply
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  size="small"
                  type="button"
                  variant="outlined"
                  onClick={handleReset}
                >
                  Clear
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default FilterPanel;
