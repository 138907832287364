import { Condition } from "../components/RulesForm/RulesForm.interface";

export const getConditionById = (
  conditions: Condition[] | null,
  id: number
) => {
  if (!conditions) {
    return null;
  }

  return conditions?.find((condition) => condition.id === id);
};
