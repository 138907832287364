import { FunctionComponent } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography } from "@achieve/sunbeam";
import { yupResolver } from "@hookform/resolvers/yup";
import routes from "constants/routes";
import ReevaluateLoansForm from "modules/Reevaluations/components/ReevaluateLoansForm/ReevaluateLoansForm";
import useSessionActions from "modules/Reevaluations/hooks/useSessionActions/useSessionActions";

import { ReevaluateLoansFormValues } from "./ReevaluateLoansPage.interface";
import {
  defaultFormValues,
  getPayload,
  validationSchema,
} from "./ReevaluateLoansPage.utils";

const ReevaluateLoansPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const { createSession, isLoading } = useSessionActions();
  const methods = useForm<ReevaluateLoansFormValues>({
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
  });
  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = methods;
  const submitButtonDisabled = !isValid || isLoading;

  const navigateToReevaluations = () => {
    navigate(routes.reevaluations);
  };

  const handleFormSubmit = async (values: ReevaluateLoansFormValues) => {
    const payload = getPayload(values);

    await createSession(payload);
    reset(defaultFormValues);
    navigateToReevaluations();
  };

  return (
    <Grid container={true} spacing={2} direction={"column"}>
      <Grid item={true}>
        <Typography component={"h1"} variant={"displayS20"} fontWeight="bold">
          {"Re-evaluate Loans"}
        </Typography>
      </Grid>
      <Grid item={true} container={true} spacing={2}>
        <FormProvider {...methods}>
          <ReevaluateLoansForm />
        </FormProvider>
      </Grid>
      <Grid
        item={true}
        container={true}
        spacing={2}
        justifyContent={"flex-end"}
      >
        <Grid item={true}>
          <Button
            variant={"outlined"}
            size={"small"}
            disabled={isLoading}
            onClick={navigateToReevaluations}
            data-testid={"reevaluate-loans-cancel"}
          >
            {"Cancel"}
          </Button>
        </Grid>
        <Grid item={true}>
          <Button
            size={"small"}
            type={"submit"}
            onClick={handleSubmit(handleFormSubmit)}
            disabled={submitButtonDisabled}
            data-testid={"reevaluate-loans-submit"}
          >
            {"Submit"}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReevaluateLoansPage;
