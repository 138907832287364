import { createContext, useCallback, useMemo, useReducer } from "react";

import {
  IErrorNotificationAction,
  IErrorNotificationActions,
  IErrorNotificationState,
  INotification,
} from "./ErrorNotification.interface";

export const actions: IErrorNotificationActions = {
  setNotification: "SET_NOTIFICATION",
};

const initialState: IErrorNotificationState = {
  notification: null,
};

const ErrorNotificationContext = createContext(initialState);

export const reducer = (
  state: IErrorNotificationState,
  action: IErrorNotificationAction
) => {
  if (action.type === actions.setNotification) {
    return {
      ...state,
      notification: action.payload,
    };
  }
  return state;
};

const ErrorNotificationContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setNotification = useCallback((notification: INotification) => {
    dispatch({ type: actions.setNotification, payload: notification });
  }, []);

  const value = useMemo(
    () => ({ ...state, setNotification }),
    [state, setNotification]
  );

  return (
    <ErrorNotificationContext.Provider value={value}>
      {children}
    </ErrorNotificationContext.Provider>
  );
};

export { ErrorNotificationContext, ErrorNotificationContextProvider };
