import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { LDProvider } from "@sindeo/launch-darkly-provider";
import { LDFlagsNames } from "constants/flags";
import { theme } from "mui/theme";
import { logger } from "services/logger";
import appConfig from "utilities/app-config/app-config";

import App from "./App/App";
import reportWebVitals from "./reportWebVitals";

import "./styles/global.scss";

const { PLATFORM } = appConfig;

ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <LDProvider
            PLATFORM={PLATFORM}
            logger={logger}
            flagsNames={LDFlagsNames}
          >
            <App />
          </LDProvider>
        </Router>
      </ThemeProvider>
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
