import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { HEADER_CELLS } from "modules/Throttling/components/ThrottlingWrapper/Throttling/Throttling.config";

import { IThrottlingTableHeader } from "./ThrottlingTableHeader.interface";

import s from "./ThrottlingTableHeader.module.scss";

const ThrottlingTableHeader: React.FC<IThrottlingTableHeader> = ({
  order,
  orderBy,
  handleRequestSort,
}) => {
  return (
    <TableHead>
      <TableRow>
        {HEADER_CELLS.map((headerCell) => (
          <TableCell key={headerCell.propName} className={s.headerCell}>
            <TableSortLabel
              active={orderBy === headerCell.propName}
              direction={orderBy === headerCell.propName ? order : "asc"}
              onClick={(e) => handleRequestSort(e, headerCell.propName)}
              aria-label="table-sort"
            >
              {headerCell.title}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell className={s.headerCell}>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default ThrottlingTableHeader;
