import { useContext } from "react";
import { Loader } from "@sindeo/react-components";
import { ThrotllingSettingsListContext } from "modules/Throttling/context/ThrottlingSettingsList.context";
import AddThrottlingButton from "modules/Throttling/UI/AddThrottlingButton/AddThrottlingButton";

import Throttling from "./Throttling/Throttling";

const ThrottlingWrapper: React.VFC = () => {
  const {
    get: { isFetching, throttlingSettingsList },
  } = useContext(ThrotllingSettingsListContext);

  if (isFetching) {
    return <Loader />;
  } else if (!throttlingSettingsList) {
    return <p>There was an error while fetching throttling settings</p>;
  } else if (throttlingSettingsList.length === 0) {
    return (
      <>
        <p>Add throttling to start</p>
        <AddThrottlingButton />
      </>
    );
  }

  return <Throttling throtllingSettingsList={throttlingSettingsList} />;
};

export default ThrottlingWrapper;
