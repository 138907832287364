import { useNavigate } from "react-router-dom";
import { useSnackbar } from "@sindeo/react-components";
import { useMutation, useQuery } from "@sindeo/react-data-query";
import { PartnerRule } from "@sindeo/rest-services";
import { AxiosError } from "axios";
import { get } from "lodash";
import { ERRORS } from "modules/ReleaseToSales/constants/errors";
import { logger } from "services/logger";
import { Rest } from "services/services";

const useUpdateRulesEndpoints = (id?: number) => {
  const notification = useSnackbar();

  const queryKey = ["ruleById", id];
  const navigate = useNavigate();
  const {
    data: ruleById,
    isLoading: isRuleLoading,
    isFetching: isRuleFetching,
    isError: isRuleError,
  } = useQuery<PartnerRule | null>(
    queryKey,
    () => {
      if (!id) {
        return null;
      }

      return Rest.rls.getPartnerRuleById(id);
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const { mutateAsync: addRule, isLoading: isRuleAdding } = useMutation({
    mutationFn: (
      data: Pick<PartnerRule, "partner" | "active" | "conditions">
    ) => Rest.rls.postPartnerRule(data),
    onSuccess: () => {
      navigate("/release-to-sales?order=desc&orderBy=createdAt");
      notification.success({
        message: "Rule was succesfully added",
      });
    },

    onError: (err: AxiosError) => {
      logger.error("Unable to add rule", err);
      notification.error({
        message: `Unable to add rule. ${get(
          ERRORS,
          err.request?.status,
          err.message
        )}`,
      });
    },
  });

  const { mutateAsync: editRule, isLoading: isRuleEditing } = useMutation({
    mutationFn: (
      data: Pick<PartnerRule, "partner" | "active" | "conditions">
    ) => {
      return Rest.rls.updatePartnerRule(id!, data);
    },
    onSuccess: () => {
      navigate("/release-to-sales?order=desc&orderBy=updatedAt");
      notification.success({
        message: "Rule was succesfully updated",
      });
    },
    onError: (err: AxiosError) => {
      logger.error("Unable to update rule", err);
      notification.error({
        message: `Unable to update rule. ${get(
          ERRORS,
          err.request?.status,
          err.message
        )}`,
      });
    },
  });

  return {
    addRule,
    editRule,
    ruleById,
    isRuleLoading,
    isRuleFetching,
    isRuleEditing,
    isRuleAdding,
    isRuleError,
  };
};

export default useUpdateRulesEndpoints;
