import routes from "constants/routes";

export const getBreadcrumbsMap = (
  id: number,
  source?: string
): Record<string, string | undefined> => ({
  [routes.throttlingDashboard]: "Throttling dashboard",
  [routes.addThrottling]: "Add throttling",
  [`/throttling/${id}`]: source,
  [routes.rtsDashboard]: "Release to sales",
  [routes.addRts]: "Add rule",
  [routes.reevaluations]: "Re-evaluations",
  [routes.addSession]: "Re-evaluate Loans",
});
