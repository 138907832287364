import type {
  InnerTableConfig,
  InnerTableLabelConfig,
  ReevaluationSessionInnerTableProps,
} from "./ReevaluationSessionInnerTable.interface";

export const leadsCountLabelConfig: InnerTableLabelConfig[] = [
  {
    label: "Qualified",
    key: "qualifiedLeadsCount",
  },
  {
    label: "Disqualified",
    key: "disqualifiedLeadsCount",
  },
  {
    label: "Re-evaluated",
    key: "reevaluatedLeadsCount",
  },
  {
    label: "Total",
    key: "totalLeadsCount",
  },
];

export const errorsCountLabelConfig: InnerTableLabelConfig[] = [
  {
    label: "Credit Report Expired",
    key: "creditReportExpiredCount",
  },
  {
    label: "AVM Report Expired",
    key: "avmReportExpiredCount",
  },
  {
    label: "Unexpected Errors",
    key: "unexpectedErrorsCount",
  },
];

export const getTableConfig = (
  viewMode: ReevaluationSessionInnerTableProps["viewMode"]
): InnerTableConfig => {
  switch (viewMode) {
    case "LEADS":
      return {
        tableHeadTitle: "Processed Leads",
        labelConfig: leadsCountLabelConfig,
      };
    case "ERRORS":
      return {
        tableHeadTitle: "Errors",
        labelConfig: errorsCountLabelConfig,
      };
    default:
      return {
        tableHeadTitle: "",
        labelConfig: [],
      };
  }
};
