import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { FilterValues } from "modules/ReleaseToSales/components/FilterPanel/FilterPanel.interface";

const useRtsSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentParams = Object.fromEntries(Array.from(searchParams));

  const [params, setParams] = useState<FilterValues>(
    currentParams?.filter
      ? {
          [Object.keys(JSON.parse(currentParams.filter))[0]]: Object.values(
            JSON.parse(currentParams.filter)
          )[0] as string,
        }
      : {}
  );

  const defaultSearchParams = currentParams?.filter
    ? {
        orderBy: Object.keys(JSON.parse(currentParams.filter))[0],
        filterBy: Object.values(JSON.parse(currentParams.filter))[0] as string,
      }
    : {
        orderBy: "",
        filterBy: "",
      };

  const onSubmit = useCallback(
    (values: FilterValues) => {
      const newParams: any = {};

      if (values.orderBy) {
        newParams[values.orderBy] = values.filterBy;
      }

      setParams(newParams);
      setSearchParams({ ...currentParams, filter: JSON.stringify(newParams) });
    },
    [currentParams, setSearchParams]
  );

  const onReset = useCallback(() => {
    setParams({
      orderBy: "",
      filterBy: "",
    });

    const { filter, ...restCurrentParams } = currentParams;

    setSearchParams(restCurrentParams);
  }, [setSearchParams, currentParams]);

  return { onSubmit, onReset, defaultSearchParams, params };
};

export default useRtsSearchParams;
