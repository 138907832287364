import { useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@achieve/sunbeam";
import { yupResolver } from "@hookform/resolvers/yup";
import { Loader } from "@sindeo/react-components";
import { get } from "lodash";
import useConditions from "modules/ReleaseToSales/hooks/useConditions/useConditions";

import ConditionsTable from "../ConditionsTable/ConditionsTable";

import { processValues, VALIDATION_SCHEMA } from "./RulesForm.config";
import { RulesFormProps, RulesFormValues } from "./RulesForm.interface";

const RulesForm: React.FC<RulesFormProps> = ({
  onSubmit,
  pageText,
  defaultValues,
  isLoading,
  isReadonly,
}) => {
  const formMethods = useForm<RulesFormValues>({
    defaultValues,
    resolver: yupResolver(VALIDATION_SCHEMA),
    mode: "onChange",
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = formMethods;

  const submitHandler = useCallback(
    (values: RulesFormValues) => {
      if (!isValid) {
        return;
      }

      onSubmit(processValues(values));
    },
    [isValid, onSubmit]
  );

  const {
    conditions,
    isConditionsFetching,
    isConditionsLoading,
    isConditionsError,
  } = useConditions();

  if (isConditionsFetching || isConditionsLoading) {
    return <Loader />;
  }

  if (isConditionsError || !conditions) {
    return (
      <Typography data-testId="error-message">
        There is an error while fetching conditions
      </Typography>
    );
  }

  const primaryError = get(errors, "primaryId.message", "");
  const conditionsError = get(errors, "conditionIds.message", "");

  return (
    <FormProvider {...formMethods}>
      <Typography
        component={"h1"}
        variant="displayS20"
        fontWeight="bold"
        sx={{ marginBottom: "20px" }}
        data-testId="rules-form"
      >
        {pageText}
      </Typography>
      <form onSubmit={handleSubmit(submitHandler)}>
        <Grid container direction={"column"} spacing={4}>
          <Grid item>
            <Controller
              control={control}
              name="partner"
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Partner"
                  size="small"
                  error={Boolean(errors.partner)}
                  helperText={get(errors, "partner.message", "")}
                  disabled={isReadonly}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Controller
              name="active"
              control={control}
              render={({ field }) => (
                <>
                  <FormLabel>Active?</FormLabel>
                  <RadioGroup {...field} row>
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </>
              )}
            />
          </Grid>
          <Grid item>
            <ConditionsTable conditions={conditions} />
          </Grid>
          <Grid item>
            {primaryError && (
              <Typography sx={{ color: "#CB000E" }}>{primaryError}</Typography>
            )}
          </Grid>
          <Grid item>
            {conditionsError && (
              <Typography sx={{ color: "#CB000E" }}>
                {conditionsError}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="small"
              disabled={isLoading}
            >
              {pageText}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default RulesForm;
