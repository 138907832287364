import { forwardRef, useCallback, useContext } from "react";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { ErrorNotificationContext } from "modules/Throttling/context/ErrorNotification/ErrorNotification";
import { IErrorNotificationContext } from "modules/Throttling/context/ErrorNotification/ErrorNotification.interface";

import s from "./ErrorNotification.module.scss";

export const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ErrorNotification = () => {
  const errorNotificationData = useContext(ErrorNotificationContext);

  const { setNotification, notification } =
    errorNotificationData as IErrorNotificationContext;

  const handleCloseNotification = useCallback(
    () => setNotification({ isOpen: false }),
    [setNotification]
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={notification?.isOpen}
      onClose={handleCloseNotification}
      key={"top center"}
    >
      <Alert onClose={handleCloseNotification} severity="error">
        {notification?.messages?.map((message) => (
          <p className={s.errorMessage} key={message}>
            {message}
          </p>
        ))}
      </Alert>
    </Snackbar>
  );
};

export default ErrorNotification;
