import { FunctionComponent } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import type { ReevaluationSessionInnerTableProps } from "./ReevaluationSessionInnerTable.interface";
import { getTableConfig } from "./ReevaluationSessionInnerTable.utils";

const ReevaluationSessionInnerTable: FunctionComponent<
  ReevaluationSessionInnerTableProps
> = ({ data, viewMode }) => {
  const { labelConfig, tableHeadTitle } = getTableConfig(viewMode);

  return (
    <Table size={"small"} sx={{ margin: 0 }}>
      <TableHead>
        <TableRow>
          <TableCell align={"right"}>{tableHeadTitle}</TableCell>
          <TableCell>{"Amount"}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {labelConfig.map(({ key, label }) => (
          <TableRow key={key}>
            <TableCell align={"right"}>{label}</TableCell>
            <TableCell>{data[key] ?? 0}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ReevaluationSessionInnerTable;
