import { FunctionComponent } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { MenuItem, TextField } from "@achieve/sunbeam";

import type { SessionsTableFilterSelectProps } from "./SessionsTableFilterSelect.interface";

export const SessionsTableFilterSelect: FunctionComponent<
  SessionsTableFilterSelectProps
> = ({ options, name, ...restProps }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, name } }) => (
        <TextField
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          name={name}
          size={"small"}
          select={true}
          {...restProps}
        >
          <MenuItem value={""} sx={{ fontSize: "16px" }}>
            {"None"}
          </MenuItem>
          {options.map((value) => (
            <MenuItem value={value} key={value} sx={{ fontSize: "16px" }}>
              {value}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};
