import { useSnackbar } from "@sindeo/react-components";
import { useQuery } from "@sindeo/react-data-query";
import type {
  PageableResponse,
  ReevaluationSession,
} from "@sindeo/rest-services";
import { logger } from "services/logger";
import { Rest } from "services/services";
import { getSortQueryParam } from "utilities/queryParams";

import type {
  UseReevaluationSessionsOptions,
  UseReevaluationSessionsResult,
} from "./useReevaluationSessions.interface";

export const GET_SESSIONS_QUERY_KEY = "get-reevaluation-sessions";

const useReevaluationSessions = ({
  size,
  page,
  order,
  orderBy,
  declineReason,
  source,
  status,
}: UseReevaluationSessionsOptions): UseReevaluationSessionsResult => {
  const notification = useSnackbar();

  const { data, isFetching } = useQuery<
    PageableResponse<ReevaluationSession[]>,
    Error
  >({
    queryKey: [
      GET_SESSIONS_QUERY_KEY,
      size,
      page,
      order,
      orderBy,
      declineReason,
      source,
      status,
    ],
    queryFn: () =>
      Rest.pls.getAllReevaluationSessions({
        size,
        page,
        sort: getSortQueryParam({ order, orderBy }),
        declineReason,
        source,
        status,
      }),
    onError: (err: Error) => {
      logger.error("Unable to fetch sessions.", err);
      notification.error({
        message: "Unable to fetch sessions.",
      });
    },
  });

  return {
    data,
    isLoading: isFetching,
  };
};

export default useReevaluationSessions;
