export const TIMESHORTCUTS_VALUES = [
  {
    label: "Midnight",
    value: "00:00:00",
  },
  {
    label: "6 a.m.",
    value: "06:00:00",
  },
  {
    label: "Noon",
    value: "12:00:00",
  },
  {
    label: "6 p.m.",
    value: "18:00:00",
  },
];
