import { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { IconButton, Menu, MenuItem } from "@mui/material";
import dayjs from "dayjs";
import { TIMESHORTCUTS_VALUES } from "modules/Throttling/constants/timeshortcuts-values";

import { ITimeshortcutsProps } from "./Timeshortcuts.interface";

import s from "./Timeshortcuts.module.scss";

export const Timeshortcut: React.FC<{
  onClick: (value: string) => void;
  label: string;
  value: string;
}> = ({ label, onClick, value }) => {
  const handleClick = useCallback(() => onClick(value), [onClick, value]);
  return (
    <MenuItem onClick={handleClick} key={label}>
      {label}
    </MenuItem>
  );
};

const Timeshortcuts: React.FC<ITimeshortcutsProps> = ({ name }) => {
  const { setValue } = useFormContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const setTime = useCallback(
    (value: string) => {
      setValue(name, value, { shouldTouch: true, shouldValidate: true });
      handleClose();
    },
    [name, setValue, handleClose]
  );

  const setCurrentTime = useCallback(() => {
    setTime(dayjs().format("HH:mm:ss"));
  }, [setTime]);

  return (
    <div className={s.container}>
      <button type="button" onClick={setCurrentTime}>
        Now
      </button>
      <IconButton
        aria-label="timeshortcuts-button"
        id="timeshortcuts-button"
        aria-controls={open ? "timeshortcuts-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <AccessTimeIcon />
      </IconButton>
      <Menu
        id="timeshortcuts-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "timeshortcuts-button",
        }}
      >
        <MenuItem disabled>Choose a time</MenuItem>
        <MenuItem onClick={setCurrentTime}>Now</MenuItem>
        {TIMESHORTCUTS_VALUES.map((shortcut) => (
          <Timeshortcut
            label={shortcut.label}
            onClick={setTime}
            value={shortcut.value}
            key={shortcut.label}
          />
        ))}
      </Menu>
    </div>
  );
};

export default Timeshortcuts;
