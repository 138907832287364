import type { OrderBy } from "@sindeo/rest-services";
import type { TableHeadConfig } from "entities/table-head-config.interface";

export const TABLE_HEAD_CELLS: TableHeadConfig<OrderBy>[] = [
  {
    title: "ID",
    align: "left",
    value: "id",
    sortable: true,
  },
  {
    title: "Partner",
    align: "left",
    value: "partner",
    sortable: true,
  },
  {
    title: "Condition",
    align: "left",
    value: "conditionId",
  },
  {
    title: "Active",
    align: "center",
    value: "active",
    sortable: true,
  },
  {
    title: "Created at",
    align: "left",
    value: "createdAt",
    sortable: true,
  },
  {
    title: "Updated at",
    align: "left",
    value: "updatedAt",
    sortable: true,
  },
  {
    title: "Actions",
    align: "center",
    value: "actions" as OrderBy,
  },
];
