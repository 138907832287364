import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Order } from "@sindeo/rest-services";
import { SortConfig } from "entities/types/order.type";
import { toNumber, toString } from "lodash";

import { UseTableHandlersResult } from "./useTableHandlers.interface";

const useTableHandlers = <T = string>(
  defaultSort: SortConfig<T>
): UseTableHandlersResult<T> => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentParams = Object.fromEntries(Array.from(searchParams));

  const paramsSize = toNumber(searchParams.get("size"));
  const paramsPage = toNumber(searchParams.get("page"));
  const paramsOrder = searchParams.get("order") as Order;
  const paramsOrderBy = searchParams.get("orderBy") as unknown as T;

  const [page, setPage] = useState(paramsPage || 0);
  const [size, setSize] = useState(paramsSize || 10);
  const [sort, setSort] = useState<SortConfig<T>>({
    order: paramsOrder || defaultSort.order,
    orderBy: paramsOrderBy || defaultSort.orderBy,
  });

  const handleChangePage = useCallback(
    (_: unknown, newPage: number) => {
      setPage(newPage);
      setSearchParams({ ...currentParams, page: toString(newPage) });
    },

    [setSearchParams, currentParams]
  );

  const handleRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSize(parseInt(event.target.value, 10));
      setPage(0);
      setSearchParams({ ...currentParams, size: event.target.value });
    },
    [setSearchParams, currentParams]
  );

  const handleChangeOrder = useCallback(
    (newOrderBy: T) => {
      const isSameColumn = newOrderBy === sort.orderBy;
      let newSort: SortConfig<T> = {
        orderBy: newOrderBy,
        order: "asc",
      };

      if (isSameColumn) {
        newSort = { ...newSort, order: sort.order === "asc" ? "desc" : "asc" };
      }

      setSort(newSort);
      setSearchParams({
        ...currentParams,
        order: `${newSort.order}`,
        orderBy: `${newSort.orderBy}`,
      });
    },
    [sort, currentParams, setSearchParams]
  );

  return {
    page,
    size,
    handleChangePage,
    handleRowsPerPageChange,
    handleChangeOrder,
    sort,
  };
};

export default useTableHandlers;
