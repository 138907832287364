import { IFieldValues, IFormConfig } from "entities/form-config.interface";
import { TIMEZONES_MENU_ITEMS } from "modules/Throttling/constants/timezones";
import * as yup from "yup";

export const FORM_CONFIG: IFormConfig<IFieldValues>[] = [
  {
    name: "source",
    label: "Source",
  },
  {
    name: "timezone",
    label: "Timezone",
    isSelect: true,
    options: TIMEZONES_MENU_ITEMS,
    formFieldType: "autocomplete",
  },
  {
    name: "leadsPerHour",
    label: "Leads Per Hour",
    helperText: "Leave empty to disable throttling per hour",
    formFieldType: "number",
  },
  {
    name: "leadsPerDay",
    label: "Leads Per Day",
    helperText: "Leave empty to disable throttling per day",
    formFieldType: "number",
  },
  {
    name: "throttlingType",
    label: "Throttling Type",
    isSelect: true,
    options: [
      { label: "Qualified leads", value: "QUALIFIED_LEADS" },
      { label: "All leads", value: "ALL_LEADS" },
    ],
  },
];

export const getWorkingHoursConfig = (
  index: number
): IFormConfig<IFieldValues>[] => {
  return [
    {
      name: `workingHoursSettings.${index}.weekday`,
      label: "Weekday",
      isSelect: true,
      options: [
        { label: "Monday", value: "MONDAY" },
        { label: "Tuesday", value: "TUESDAY" },
        { label: "Wednesday", value: "WEDNESDAY" },
        { label: "Thursday", value: "THURSDAY" },
        { label: "Friday", value: "FRIDAY" },
        { label: "Saturday", value: "SATURDAY" },
        { label: "Sunday", value: "SUNDAY" },
      ],
    },
    {
      name: `workingHoursSettings.${index}.fromTime`,
      label: "From Time",
      formFieldType: "timepicker",
    },
    {
      name: `workingHoursSettings.${index}.toTime`,
      label: "To Time",
      formFieldType: "timepicker",
    },
  ];
};

export const VALIDATION_SCHEMA = yup
  .object({
    source: yup.string().required("Source is required"),
    timezone: yup.string(),
    leadsPerHour: yup.lazy((value) =>
      value === "" ? yup.string() : yup.number()
    ),
    leadsPerDay: yup.lazy((value) =>
      value === "" ? yup.string() : yup.number()
    ),
    throttlingType: yup.string(),
    workingHoursSettings: yup.array().of(
      yup.object({
        weekday: yup.string(),
        fromTime: yup.string().required("From time is required"),
        toTime: yup.string().required("To time is required"),
      })
    ),
  })
  .required();
