import { useContext } from "react";
import { IThrottlingSettings } from "@sindeo/rest-services";
import { ThrotllingSettingsListContext } from "modules/Throttling/context/ThrottlingSettingsList.context";

const useThrottlingSettingsById = (id: number | undefined) => {
  const {
    get: { throttlingSettingsList },
  } = useContext(ThrotllingSettingsListContext);

  const throttlingSettingsById: IThrottlingSettings | undefined =
    throttlingSettingsList?.find(
      (throttlingSettings) => throttlingSettings.id === id
    );

  return throttlingSettingsById;
};

export default useThrottlingSettingsById;
