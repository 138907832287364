import { Link } from "@achieve/sunbeam";
import { CheckCircleOutline, Delete, Edit } from "@mui/icons-material";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { Loader } from "@sindeo/react-components";
import { PartnerCondition } from "@sindeo/rest-services";
import cn from "classnames";
import { getConditionById } from "modules/ReleaseToSales/utils/common.utils";
import { findDeletingById, formatDate } from "utilities/common.utils";

import DialogComponent from "components/DialogComponent/DialogComponent";

import { RulesTableRowProps } from "./RulesTableRow.interface";
import useRulesTableRow from "./useRulesTableRow";

import s from "./RulesTableRow.module.scss";

const RulesTableRow: React.FC<RulesTableRowProps> = ({
  row,
  conditions,
  deleteRule,
  deletingIds,
}) => {
  const {
    id,
    partner,
    conditions: rowConditions,
    createdAt,
    updatedAt,
    active,
  } = row;

  const { isDialogOpen, handleOpenDialog, handleCloseDialog, handleSubmit } =
    useRulesTableRow(id, deleteRule);

  const sortedRowConditions: PartnerCondition[] = [];
  rowConditions.forEach((condition) => {
    if (condition.isPrimary) {
      sortedRowConditions.unshift(condition);
    } else {
      sortedRowConditions.push(condition);
    }
  });

  return (
    <>
      <TableRow key={id}>
        <TableCell>{id}</TableCell>
        <TableCell>{partner}</TableCell>
        <TableCell>
          {sortedRowConditions.map((condition: PartnerCondition) => {
            const primaryCondition =
              condition.isPrimary && rowConditions.length > 1;
            const conditionsClasses = cn(s.conditions, {
              [s.primary]: primaryCondition,
            });
            const _condition = getConditionById(conditions, condition.id);

            if (!_condition) {
              return null;
            }

            return (
              <p className={conditionsClasses} key={_condition.id}>
                {`${_condition.description}${
                  primaryCondition ? " (Primary)" : ""
                }`}
              </p>
            );
          })}
        </TableCell>
        <TableCell align="center">
          {active && <CheckCircleOutline fontSize="small" color="success" />}
        </TableCell>
        <TableCell>{formatDate(createdAt)}</TableCell>
        <TableCell>{formatDate(updatedAt)}</TableCell>
        <TableCell align="center">
          <Link href={`/release-to-sales/${id}`}>
            <IconButton>
              <Edit />
            </IconButton>
          </Link>
          <IconButton onClick={handleOpenDialog}>
            {findDeletingById(deletingIds, id) ? <Loader /> : <Delete />}
          </IconButton>
        </TableCell>
      </TableRow>
      <DialogComponent
        isOpen={isDialogOpen}
        title={`Would you like to delete ${partner}?`}
        submitButtonText="Delete"
        submitButtonColor="red"
        handleClose={handleCloseDialog}
        submitAction={handleSubmit}
      />
    </>
  );
};

export default RulesTableRow;
