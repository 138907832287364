import { useEffect } from "react";
import {
  Backdrop,
  Paper,
  Table as BaseTable,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Loader } from "@sindeo/react-components";
import { OrderBy, PartnerRule } from "@sindeo/rest-services";
import { SortConfig } from "entities/types/order.type";
import useTableHandlers from "hooks/useTableHandlers/useTableHandlers";
import { toNumber } from "lodash";
import useConditions from "modules/ReleaseToSales/hooks/useConditions/useConditions";
import useRtsSearchParams from "modules/ReleaseToSales/hooks/useRtsSearchParams/useRtsSearchParams";
import useRulesEndpoints from "modules/ReleaseToSales/hooks/useRulesEndpoints/useRulesEndpoints";

import TableHead from "components/TableHead/TableHead";

import FilterPanel from "../FilterPanel/FilterPanel";

import RulesTableRow from "./RulesTableRow/RulesTableRow";
import { TABLE_HEAD_CELLS } from "./Table.constants";

import s from "./Table.module.scss";

const defaultSort: SortConfig<OrderBy> = {
  order: "asc",
  orderBy: "id",
};

const Table = () => {
  const { onSubmit, onReset, defaultSearchParams, params } =
    useRtsSearchParams();
  const {
    page,
    size,
    handleChangePage,
    handleRowsPerPageChange,
    handleChangeOrder,
    sort,
  } = useTableHandlers<OrderBy>(defaultSort);

  const {
    data,
    partners,
    isLoading,
    isFetching,
    isError,
    deleteRule,
    deletingIds,
  } = useRulesEndpoints(page, size, sort, params);

  const { conditions, isConditionsFetching } = useConditions();

  useEffect(() => {
    if (data && page !== 0 && (page >= data?.totalPages || isNaN(page))) {
      handleChangePage(undefined, 0);
    }
  }, [data, page, handleChangePage]);

  let tableContent = null;

  if (isError || !partners) {
    tableContent = "There is an error while fetching partner rules";
  } else if (!data || data.content.length === 0) {
    tableContent = "There are no partner rules to display";
  }

  return (
    <>
      <FilterPanel
        partners={partners}
        conditions={conditions}
        onSubmit={onSubmit}
        onReset={onReset}
        defaultSearchParams={defaultSearchParams}
        isLoading={isFetching || isConditionsFetching}
      />

      <TableContainer
        component={Paper}
        sx={{ maxHeight: "75vh", position: "relative" }}
      >
        <Backdrop
          open={isLoading || isFetching}
          sx={{
            position: "absolute",
            backgroundColor: "white",
          }}
          aria-label="backdrop"
        >
          <Loader />
        </Backdrop>
        <BaseTable stickyHeader sx={{ marginTop: 0 }}>
          <TableHead<OrderBy>
            columns={TABLE_HEAD_CELLS}
            onOrderByChange={handleChangeOrder}
            sort={sort}
          />
          <TableBody aria-label="table-body">
            {tableContent && (
              <TableRow className={s.message}>
                <TableCell colSpan={4}>{tableContent}</TableCell>
              </TableRow>
            )}

            {data?.content.map((row: PartnerRule) => (
              <RulesTableRow
                row={row}
                conditions={conditions}
                key={row.id}
                deleteRule={deleteRule}
                deletingIds={deletingIds}
              />
            ))}
          </TableBody>
        </BaseTable>
      </TableContainer>

      {Boolean(data?.totalElements) && (
        <TablePagination
          className={s.pagination}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={toNumber(data?.totalElements)}
          rowsPerPage={toNumber(size)}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
    </>
  );
};

export default Table;
