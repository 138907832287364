const routes = {
  loginRedirect: "/login/callback",
  throttlingDashboard: "/throttling",
  addThrottling: "/throttling/add",
  throttling: "/throttling/:id",
  rtsDashboard: "/release-to-sales",
  addRts: "/release-to-sales/add",
  rts: "release-to-sales/:id",
  reevaluations: "/reevaluations",
  addSession: "/reevaluations/add",
};

export default routes;
