import { MenuItemOption } from "entities/menu-item-option.interface";

import { IAutocompleteComponentProps } from "./AutocompleteComponent.interface";

export const getOptionLabel = ({ label }: MenuItemOption) => label ?? "";
export const isOptionEqualToValue = (
  option: MenuItemOption,
  selected: MenuItemOption
) => option.value === selected.value;

export const getSelectedOption = (
  options: IAutocompleteComponentProps["options"],
  initialValue?: string
): MenuItemOption | null =>
  options?.find(({ value }) => value === initialValue) ?? null;
