import {
  Logger,
  LogLevel,
  LogTransport,
  SENSITIVE_FIELDS,
} from "@sindeo/logging";
import config from "utilities/app-config/app-config";

const { APP_VERSION, DATADOG, PLATFORM, MASK_SENSITIVE_LOGS } = config;

const logger = new Logger({
  clientToken: DATADOG,
  level: LogLevel.DEBUG,
  sensitiveFields: MASK_SENSITIVE_LOGS ? SENSITIVE_FIELDS : [],
  transport:
    process.env.NODE_ENV === "production"
      ? LogTransport.NETWORK
      : LogTransport.CONSOLE,
  tags: {
    app: "lnd-ingestion",
    bu: "lnd",
    env: PLATFORM === "qa" ? "sbx" : PLATFORM,
    service: "lendage-partner-leads-console-app",
    version: APP_VERSION,
  }
});

export { logger };
