import {
  CentralInfoService,
  PartnerLeadsService,
  ReleaseToSalesService,
} from "@sindeo/rest-services";

export class Rest {
  static get cis(): CentralInfoService {
    return CentralInfoService.getInstance();
  }

  static get pls(): PartnerLeadsService {
    return PartnerLeadsService.getInstance();
  }

  static get rls(): ReleaseToSalesService {
    return ReleaseToSalesService.getInstance();
  }
}
