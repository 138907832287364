import { Grid } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import { useLDFlags } from "hooks/useLDFlags/useLDFlags";
import NavigationBar from "UI/NavigationBar/NavigationBar";

const AppLayout: React.FC = ({ children }) => {
  const { oktaAuthEnabled } = useLDFlags();
  const { authState } = useOktaAuth();

  if (oktaAuthEnabled && !authState?.isAuthenticated) {
    return <div>{children}</div>;
  }

  return (
    <Grid container spacing={0}>
      <Grid item md={3} lg={2}>
        <NavigationBar />
      </Grid>
      <Grid item md={9} lg={10}>
        {children}
      </Grid>
    </Grid>
  );
};

export default AppLayout;
