import { FunctionComponent, ReactNode, useState } from "react";
import { Box, Collapse, Grid } from "@achieve/sunbeam";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { ReevaluationStatus } from "@sindeo/rest-services";
import AbortSessionButton from "modules/Reevaluations/components/AbortSessionButton/AbortSessionButton";
import ReevaluationSessionInnerTable from "modules/Reevaluations/components/ReevaluationSessionInnerTable/ReevaluationSessionInnerTable";
import { COLUMNS } from "modules/Reevaluations/components/ReevaluationSessionsTable/ReevaluationSessionsTable.utils";
import { DATE_FORMAT, formatDate, TIME_FORMAT } from "utilities/common.utils";

import type { ReevaluationSessionRowProps } from "./ReevaluationSessionRow.interface";

import styles from "./ReevaluationSessionRow.module.scss";

const statusLabelConfig: Record<
  ReevaluationStatus,
  { label: string; className?: string }
> = {
  RUNNING: {
    label: "Running",
    className: styles.info,
  },
  COMPLETED: {
    label: "Completed",
    className: styles.success,
  },
  ABORTED: {
    label: "Aborted",
    className: styles.error,
  },
  UNEXPECTED_ERROR: {
    label: "Unexpected Error",
    className: styles.error,
  },
  SERVER_SHUTDOWN: {
    label: "Server Shutdown",
    className: styles.error,
  },
};

const getDateCell = (date: string): ReactNode =>
  date ? (
    <>
      {formatDate(date, DATE_FORMAT)}
      <br />
      {formatDate(date, TIME_FORMAT)}
    </>
  ) : (
    "-"
  );

const ReevaluationSessionRow: FunctionComponent<
  ReevaluationSessionRowProps
> = ({ data }) => {
  const [open, setOpen] = useState(false);
  const {
    title,
    source,
    declineReason,
    status,
    startDate,
    endDate,
    id,
    createdAt,
  } = data;
  const statusConfig = statusLabelConfig[status];

  const handleCollapseClick = () => {
    setOpen((prevValue) => !prevValue);
  };

  return (
    <>
      <TableRow className={styles.row}>
        <TableCell>
          <IconButton
            size={"small"}
            onClick={handleCollapseClick}
            data-testid={"session-row-collapse-button"}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{title || "-"}</TableCell>
        <TableCell className={statusConfig.className}>
          {statusConfig.label}
        </TableCell>
        <TableCell>{getDateCell(createdAt)}</TableCell>
        <TableCell>{getDateCell(startDate)}</TableCell>
        <TableCell>{getDateCell(endDate)}</TableCell>
        <TableCell>{source || "-"}</TableCell>
        <TableCell sx={{ wordBreak: "break-word" }}>{declineReason}</TableCell>
        <TableCell>
          {status === "RUNNING" && <AbortSessionButton id={id} title={title} />}
        </TableCell>
      </TableRow>
      <TableRow className={styles.collapseRow}>
        <TableCell className={styles.collapseCell} colSpan={COLUMNS.length}>
          <Collapse in={open} unmountOnExit={true}>
            <Box sx={{ padding: 2 }}>
              <Grid
                container={true}
                columnSpacing={4}
                justifyContent={"center"}
              >
                <Grid item={true} md={6}>
                  <ReevaluationSessionInnerTable
                    data={data}
                    viewMode={"LEADS"}
                  />
                </Grid>
                <Grid item={true} md={6}>
                  <ReevaluationSessionInnerTable
                    data={data}
                    viewMode={"ERRORS"}
                  />
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ReevaluationSessionRow;
