import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@sindeo/react-components";
import { IThrottlingData } from "@sindeo/rest-services";
import { DEFAULT_VALUES } from "modules/Throttling/components/AddThrottling/AddThrottling.constants";
import WorkingHours from "modules/Throttling/components/WorkingHours/WorkingHours";
import useCallbackPrompt from "modules/Throttling/hooks/useCallbackPrompt/useCallbackPrompt";
import ControlledTextField from "modules/Throttling/UI/ControlledTextField/ControlledTextField";
import ErrorNotification from "modules/Throttling/UI/ErrorNotification/ErrorNotification";
import {
  FORM_CONFIG,
  VALIDATION_SCHEMA,
} from "utilities/form-config/form-config";

import DialogComponent from "components/DialogComponent/DialogComponent";

import { IThrottlingFormProps } from "./ThrottlingForm.interface";

import s from "./ThrottlingForm.module.scss";

const ThrottlingForm: React.FC<IThrottlingFormProps> = ({
  defaultValues,
  isLoading,
  onSubmit,
}) => {
  const methods = useForm<IThrottlingData>({
    resolver: yupResolver(VALIDATION_SCHEMA),
    shouldUseNativeValidation: false,
    mode: "all",
    defaultValues,
  });

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    methods.formState.isDirty &&
      !methods.formState.isSubmitted &&
      !methods.formState.isSubmitting
  );

  const onReset = useCallback(() => {
    methods.reset(DEFAULT_VALUES, {
      keepDirty: true,
    });
  }, [methods]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className={s.form}>
          <div className={s.form_fields}>
            {FORM_CONFIG.map((config) => (
              <div className={s.form_field} key={config.name}>
                <ControlledTextField
                  isSelect={config.isSelect}
                  name={config.name}
                  label={config.label}
                  options={config.options}
                  helperText={config.helperText}
                  formFieldType={config.formFieldType}
                />
              </div>
            ))}
          </div>
          <WorkingHours />
          <Button
            type="submit"
            disabled={!methods.formState.isValid}
            loading={isLoading || methods.formState.isSubmitting}
            buttonSize="small"
            className={s.form_submit}
          >
            Save
          </Button>
          <Button
            onClick={onReset}
            type="button"
            buttonStyle="outline"
            buttonSize="small"
          >
            Clear form
          </Button>
        </form>
      </FormProvider>
      <DialogComponent
        isOpen={showPrompt}
        title="Would you like to discard unsaved changes?"
        submitButtonText="Ok"
        handleClose={cancelNavigation}
        submitAction={confirmNavigation}
      />
      <ErrorNotification />
    </>
  );
};

export default ThrottlingForm;
