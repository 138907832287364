import { ControllerRenderProps, FieldValues } from "react-hook-form";

export const getHandleConditionChange =
  (
    conditionIds: number[],
    conditionId: number,
    field: ControllerRenderProps<FieldValues, "conditionIds">
  ) =>
  () => {
    const selectedIndex = conditionIds.indexOf(conditionId);

    if (selectedIndex > -1) {
      field.onChange(
        conditionIds.filter(
          (_conditionId: number) => _conditionId !== conditionId
        )
      );
    } else {
      field.onChange([...conditionIds, conditionId]);
    }
  };
