import { FunctionComponent, useEffect } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Grid, TextField, Typography } from "@achieve/sunbeam";
import { ControlledDateTimePicker } from "@sindeo/react-forms";
import { DeclineReasonsFormItem } from "modules/Reevaluations/components/DeclineReasonsFormItem/DeclineReasonsFormItem";
import moment from "moment";

import styles from "./ReevaluateLoansForm.module.scss";

const ReevaluateLoansForm: FunctionComponent = () => {
  const { control, trigger } = useFormContext();

  const [startDate, endDate] = useWatch({
    control,
    name: ["startDate", "endDate"],
  });

  useEffect(() => {
    if (startDate && endDate) {
      trigger(["startDate", "endDate"]);
    }
  }, [trigger, startDate, endDate]);

  return (
    <>
      <Grid item={true} xs={12} md={4}>
        <Controller
          control={control}
          name={"title"}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <TextField
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              name={name}
              size={"small"}
              label={"Session Name"}
              className={styles.textField}
            />
          )}
        />
      </Grid>
      <Grid item={true} xs={12}>
        <Typography component={"p"}>{"Set filters for loans: "}</Typography>
      </Grid>
      <Grid item={true} xs={12}>
        <Grid item={true} xs={12} md={5}>
          <DeclineReasonsFormItem
            required={true}
            className={styles.textField}
          />
        </Grid>
      </Grid>
      <Grid item={true} xs={12} md={5}>
        <Controller
          control={control}
          name={"source"}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <TextField
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              name={name}
              size={"small"}
              label={"Source"}
              className={styles.textField}
            />
          )}
        />
      </Grid>
      <Grid item={true} container={true} xs={12} spacing={2}>
        <Grid item={true} xs={12} md={3}>
          <ControlledDateTimePicker
            name={"startDate"}
            datePickerProps={{
              maxDateTime: moment(),
            }}
            textFieldProps={{
              label: "Start Date",
              required: true,
              fullWidth: true,
            }}
          />
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <ControlledDateTimePicker
            name={"endDate"}
            datePickerProps={{
              maxDateTime: moment(),
            }}
            textFieldProps={{
              label: "End Date",
              fullWidth: true,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ReevaluateLoansForm;
