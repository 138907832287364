import { FunctionComponent, useCallback, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Button, Drawer, Grid, TextField, Typography } from "@achieve/sunbeam";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { REEVALUATION_STATUSES } from "@sindeo/rest-services";
import { DeclineReasonsFormItem } from "modules/Reevaluations/components/DeclineReasonsFormItem/DeclineReasonsFormItem";
import { SessionsTableFilterSelect } from "modules/Reevaluations/components/SessionsTableFilterSelect/SessionsTableFilterSelect";

import type {
  FilterBarFormValues,
  SessionsTableFilterBarProps,
} from "./SessionsTableFilterBar.interface";
import {
  filterBarDefaultFormValues,
  getInitialFormValues,
} from "./SessionsTableFilterBar.utils";

import styles from "./SessionsTableFilterBar.module.scss";

export const SessionsTableFilterBar: FunctionComponent<
  SessionsTableFilterBarProps
> = ({ initialValues, onSubmit, onReset }) => {
  const [filterBarOpen, setFilterBarOpen] = useState(false);
  const methods = useForm<FilterBarFormValues>({
    defaultValues: getInitialFormValues(initialValues),
  });
  const { handleSubmit, reset, control } = methods;

  const handleOpen = useCallback(() => {
    setFilterBarOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setFilterBarOpen(false);
  }, []);

  const handleFormSubmit = useCallback(
    (values: FilterBarFormValues) => {
      onSubmit(values);
      handleClose();
    },
    [handleClose, onSubmit]
  );

  const handleReset = useCallback(() => {
    reset(filterBarDefaultFormValues);
    onReset();
    handleClose();
  }, [handleClose, onReset, reset]);

  return (
    <>
      <Button
        size={"small"}
        variant={"text"}
        onClick={handleOpen}
        data-testid={"sessions-table-filter-button"}
      >
        {"Filter"}
      </Button>
      <Drawer
        anchor={"right"}
        open={filterBarOpen}
        onClose={handleClose}
        data-testid={"sessions-table-filter-drawer"}
        classes={{ paper: styles.filterDrawer }}
      >
        <FormProvider {...methods}>
          <Grid container={true} spacing={2} direction={"column"}>
            <Grid item={true} className={styles.heading}>
              <Typography variant={"displayS20"}>{"Filters"}</Typography>
              <IconButton
                onClick={handleClose}
                data-testid={"sessions-table-filter-close"}
              >
                <CloseIcon color={"inherit"} />
              </IconButton>
            </Grid>
            <Grid item={true}>
              <Controller
                control={control}
                name={"source"}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <TextField
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    label={"Source"}
                    size={"small"}
                    className={styles.textField}
                  />
                )}
              />
            </Grid>
            <Grid item={true}>
              <DeclineReasonsFormItem className={styles.textField} />
            </Grid>
            <Grid item={true}>
              <SessionsTableFilterSelect
                name={"status"}
                label={"Status"}
                options={REEVALUATION_STATUSES as unknown as string[]}
                className={styles.textField}
              />
            </Grid>
            <Grid item={true}>
              <Button
                size={"xsmall"}
                type={"submit"}
                fullWidth={true}
                onClick={handleSubmit(handleFormSubmit)}
                data-testid={"sessions-table-filter-submit"}
              >
                {"Save"}
              </Button>
            </Grid>
            <Grid item={true}>
              <Button
                size={"xsmall"}
                variant={"outlined"}
                fullWidth={true}
                onClick={handleReset}
                data-testid={"sessions-table-filter-reset"}
              >
                {"Reset"}
              </Button>
            </Grid>
          </Grid>
        </FormProvider>
      </Drawer>
    </>
  );
};
