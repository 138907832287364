import { createContext } from "react";
import { noop } from "lodash";
import useThrottlingEndpoints from "modules/Throttling/hooks/useThrottlingEndpoints/useThrottlingEndpoints";
import { IUseThrottlingEndpoints } from "modules/Throttling/hooks/useThrottlingEndpoints/useThrottlingEndpoints.interface";

const defaultContext: IUseThrottlingEndpoints = {
  get: {
    isFetching: true,
    throttlingSettingsList: null,
  },
  create: { createThrottlingSettings: noop, isCreating: false },
  update: { updateThrottlingSettings: noop, isUpdating: false },
  delete: {
    deletingIds: [],
    deleteThrottlingSettings: noop,
  },
};

export const ThrotllingSettingsListContext =
  createContext<IUseThrottlingEndpoints>(defaultContext);

const ThrotllingSettingsListContextProvider: React.FC = ({ children }) => {
  const value = useThrottlingEndpoints();
  return (
    <ThrotllingSettingsListContext.Provider value={value}>
      {children}
    </ThrotllingSettingsListContext.Provider>
  );
};

export default ThrotllingSettingsListContextProvider;
