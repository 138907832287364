import { useLocation } from "react-router-dom";
import { AchieveLogoIcon } from "@sindeo/react-components";
import Breadcrumbs from "UI/Breadcrumbs/Breadcrumbs";

import s from "./Header.module.scss";

const Header = () => {
  const pathname = useLocation().pathname;

  return (
    <header className={s.header}>
      <AchieveLogoIcon />
      {pathname !== "/" && <Breadcrumbs />}
    </header>
  );
};

export default Header;
