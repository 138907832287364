import { Button } from "@achieve/sunbeam";

import s from "./AddThrottlingButton.module.scss";

const AddThrottlingButton = () => {
  return (
    <Button href="/throttling/add" className={s.addLink} size="small">
      Add throttling
    </Button>
  );
};

export default AddThrottlingButton;
