import moment from "moment-timezone";

export const DATE_FORMAT = "MM/DD/YYYY";
export const TIME_FORMAT = "hh:mm A z";
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const formatDate = (
  date: string,
  format: string = DATE_TIME_FORMAT
): string => {
  return moment(date).tz("America/Los_Angeles").locale("us").format(format);
};

export const findDeletingById = (deletingIds: number[], id: number) =>
  deletingIds.includes(id);
