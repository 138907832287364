import { MenuItem, TextField } from "@material-ui/core";
import { get } from "lodash";

import { ITextFieldComponentProps } from "./TextFieldComponent.interface";

const TextFieldComponent: React.FC<ITextFieldComponentProps> = ({
  isSelect = false,
  name,
  label,
  options,
  className,
  helperText,
  field,
  errors,
}) => {
  return (
    <TextField
      {...field}
      label={label}
      id={`${name}-component`}
      select={isSelect}
      variant="outlined"
      size="small"
      className={className}
      helperText={get(errors[name], "message", helperText)}
      error={errors[name]}
    >
      {isSelect &&
        options?.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </TextField>
  );
};

export default TextFieldComponent;
