import { Condition, OrderBy } from "@sindeo/rest-services";
import { MenuItemOption } from "entities/menu-item-option.interface";

import { SelectConfig } from "./FilterPanel.interface";

export const getSelectItems = (
  orderBy: OrderBy,
  partners: string[] | null,
  conditions: Condition[] | null
): SelectConfig[] => [
  {
    name: "orderBy",
    label: "Select category",
    options: [
      {
        value: "",
        label: (<em>None</em>) as unknown as string,
      },
      {
        value: "partner",
        label: "Partner",
      },
      {
        value: "conditionId",
        label: "Condition",
      },
      {
        value: "active",
        label: "Active",
      },
    ],
  },
  {
    name: "filterBy",
    label: "Filter by",
    disabled: !orderBy,
    options: getFilterOptions(orderBy, partners, conditions),
    isAutocomplete: true,
  },
];

export const getFilterOptions = (
  orderBy: OrderBy,
  partners: string[] | null,
  conditions: Condition[] | null
): MenuItemOption[] => {
  let conditionsFilter: MenuItemOption[] = [];

  if (conditions?.length) {
    conditionsFilter = conditions.map((condition) => ({
      value: condition.id.toString(),
      label: condition.description,
    }));
  }

  switch (orderBy) {
    case "partner":
      if (partners) {
        return partners.map((partner) => ({
          value: partner,
          label: partner,
        }));
      } else {
        return [];
      }
    case "active":
      return [
        {
          value: "true",
          label: "Active",
        },
        {
          value: "false",
          label: "Inactive",
        },
      ];

    case "conditionId":
      return conditionsFilter;

    default:
      return [];
  }
};
