import { useFlags } from "@sindeo/launch-darkly-provider";
import type {
  LDFlags,
  UseLDFlagsResult,
} from "entities/launchDarkly.interface";

export const useLDFlags = (): UseLDFlagsResult => {
  const {
    isLoading,
    lenTempFeatureLrt8511PlcReevaluationsPageEnabled,
    lenTempFeatureLrt8400PlcOktaAuthEnabled,
  } = useFlags<LDFlags>();

  return {
    isLoading,
    reevaluationsPageEnabled: lenTempFeatureLrt8511PlcReevaluationsPageEnabled,
    oktaAuthEnabled: lenTempFeatureLrt8400PlcOktaAuthEnabled,
  };
};
