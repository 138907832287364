import { useCallback, useContext } from "react";
import { get, isEmpty } from "lodash";
import { ERRORS } from "modules/Throttling/constants/errors";
import { ErrorNotificationContext } from "modules/Throttling/context/ErrorNotification/ErrorNotification";
import { IErrorNotificationContext } from "modules/Throttling/context/ErrorNotification/ErrorNotification.interface";
import { logger } from "services/logger";

const useProcessError = () => {
  const errorNotificationData = useContext(ErrorNotificationContext);

  const { setNotification } =
    errorNotificationData as IErrorNotificationContext;

  const processError = useCallback(
    (e?: unknown, customMessage?: string) => {
      logger.error(e);

      const { data } = get(e, "response", {});

      if (customMessage) {
        setNotification({
          isOpen: true,
          messages: [customMessage],
        });

        return;
      }

      if (data && !isEmpty(data.subErrors)) {
        const messages: string[] = data.subErrors.map((subError: unknown) =>
          get(subError, "message", "")
        );
        setNotification({
          isOpen: true,
          messages: messages,
        });

        return;
      }

      if (data) {
        setNotification({
          isOpen: true,
          messages: [get(ERRORS, data.message, "Unexpected error")],
        });
      } else {
        setNotification({
          isOpen: true,
          messages: ["Unexpected error"],
        });
      }
    },
    [setNotification]
  );
  return { processError };
};

export default useProcessError;
