import { TextField } from "@material-ui/core";
import { get } from "lodash";

import { INumericField } from "./NumericField.interface";

const NumericField: React.FC<INumericField> = ({
  label,
  name,
  className,
  field,
  errors,
  helperText,
}) => {
  return (
    <TextField
      {...field}
      label={label}
      id={`${name}-component`}
      variant="outlined"
      size="small"
      className={className}
      type="number"
      helperText={get(errors[name], "message", helperText)}
      error={errors[name]}
      InputProps={{ inputProps: { min: 0 } }}
    />
  );
};

export default NumericField;
