import { Controller, useFormContext } from "react-hook-form";
import { Box, Paper, Table, TableBody, TableContainer } from "@achieve/sunbeam";
import { DEX_ID } from "modules/ReleaseToSales/constants/common";

import ConditionsTableHead from "./ConditionsTableHead/ConditionsTableHead";
import ConditionsTableRow from "./ConditionsTableRow/ConditionsTableRow";
import ConditionsTableToolbar from "./ConditionsTableToolbar/ConditionsTableToolbar";
import { ConditionsTableProps } from "./ConditionsTable.interface";

import s from "./ConditionsTable.module.scss";

const ConditionsTable: React.FC<ConditionsTableProps> = ({ conditions }) => {
  const { watch, control } = useFormContext();

  const optionalConditions = conditions.filter(
    (condition) => condition.id !== DEX_ID
  );

  const requiredCondition = conditions.find((condition) => condition.id === 8);

  const conditionIds = watch("conditionIds");

  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        sx={{
          maxWidth: 630,
          mb: 2,
          maxHeight: 450,
          overflowY: "scroll",
        }}
      >
        <ConditionsTableToolbar numSelected={conditionIds?.length || 0} />

        <TableContainer sx={{ border: "none !important" }}>
          <Table
            sx={{ minWidth: 200 }}
            aria-labelledby="tableTitle"
            size={"small"}
            className={s.table}
          >
            <Controller
              control={control}
              name="conditionIds"
              render={({ field }) => (
                <ConditionsTableHead
                  numSelected={conditionIds?.length || 0}
                  onSelectAllClick={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ) => {
                    if (event.target.checked) {
                      const newSelected = conditions.map(
                        (condition) => condition.id
                      );

                      field.onChange(newSelected);
                    } else {
                      field.onChange([]);
                    }
                  }}
                  rowCount={conditions.length}
                />
              )}
            />

            <TableBody>
              {requiredCondition && (
                <ConditionsTableRow
                  isItemSelected={
                    conditionIds?.indexOf(requiredCondition.id) > -1
                  }
                  condition={requiredCondition}
                />
              )}

              {optionalConditions.map((condition) => (
                <ConditionsTableRow
                  isItemSelected={conditionIds?.indexOf(condition.id) > -1}
                  key={condition.id}
                  condition={condition}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default ConditionsTable;
