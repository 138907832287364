import { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { MenuItemOption } from "entities/menu-item-option.interface";
import AutocompleteComponent from "modules/Throttling/UI/AutocompleteComponent/AutocompleteComponent";
import NumericField from "modules/Throttling/UI/NumericField/NumericField";
import TextFieldComponent from "modules/Throttling/UI/TextFieldComponent/TextFieldComponent";
import TimepickerComponent from "modules/Throttling/UI/TimepickerComponent/TimepickerComponent";

import { IControlledTextFieldProps } from "./ControlledTextField.interface";

const ControlledTextField: React.FC<IControlledTextFieldProps> = (props) => {
  const { name, formFieldType } = props;
  const { control, formState } = useFormContext();

  const renderFormField = useCallback(
    ({ field }) => {
      switch (formFieldType) {
        case "timepicker":
          return (
            <TimepickerComponent
              {...props}
              field={field}
              errors={formState.errors}
            />
          );

        case "autocomplete":
          return (
            <AutocompleteComponent
              {...props}
              field={field}
              errors={formState.errors}
            />
          );

        case "number":
          return (
            <NumericField {...props} field={field} errors={formState.errors} />
          );

        default:
          return (
            <TextFieldComponent
              {...props}
              options={props.options as MenuItemOption[]}
              field={field}
              errors={formState.errors}
            />
          );
      }
    },
    [formFieldType, formState.errors, props]
  );

  return <Controller name={name} control={control} render={renderFormField} />;
};

export default ControlledTextField;
