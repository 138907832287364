import { useState } from "react";
import { useSnackbar } from "@sindeo/react-components";
import {
  useMutation,
  useQuery,
  useQueryClient,
} from "@sindeo/react-data-query";
import {
  OrderBy,
  PartnerRule,
  PartnerRulesResponse,
} from "@sindeo/rest-services";
import { AxiosError } from "axios";
import { SortConfig } from "entities/types/order.type";
import { get, toString } from "lodash";
import { ERRORS } from "modules/ReleaseToSales/constants/errors";
import { logger } from "services/logger";
import { Rest } from "services/services";
import { getSortQueryParam } from "utilities/queryParams";

import { UseRulesEndpointsState } from "./useRulesEndpoints.interface";

const useRulesEndpoints = (
  page: number,
  size: number,
  sort: SortConfig<OrderBy>,
  params?: { partner?: string; conditionId?: number; active?: boolean }
): UseRulesEndpointsState => {
  const notification = useSnackbar();
  const [deletingIds, setDeletingIds] = useState<number[]>([]);
  const queryKey = ["rulesList", page, size, sort, params];

  const { data, isLoading, isFetching, isError } =
    useQuery<PartnerRulesResponse<PartnerRule[]> | null>(
      queryKey,
      () => {
        return Rest.rls.getPartnerRules(
          size,
          page,
          getSortQueryParam(sort),
          params
        );
      },
      {
        initialData: null,
        refetchOnWindowFocus: false,
      }
    );

  const { data: partners } = useQuery<string[] | null>(
    ["partnersList"],
    () => Rest.rls.getPartnersByQuery(),
    {
      initialData: null,
      refetchOnWindowFocus: false,
    }
  );

  const queryClient = useQueryClient();

  const { mutateAsync: deleteRule, isLoading: isRuleDeleting } = useMutation({
    mutationFn: (id: number) => {
      setDeletingIds([...deletingIds, id]);
      return Rest.rls.deletePartnerRule(toString(id));
    },

    onSuccess: () => {
      queryClient.invalidateQueries();
    },
    onError: (err: AxiosError) => {
      logger.error("Unable to delete rule", err);

      notification.error({
        message: `Unable to delete rule. ${get(
          ERRORS,
          err.request?.status,
          err.message
        )}`,
      });
    },
  });

  return {
    data,
    isLoading,
    isFetching,
    isError,
    partners,
    deleteRule,
    deletingIds,
    isRuleDeleting,
  };
};

export default useRulesEndpoints;
